.ad-page-top {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ad-labels {
	display: flex;
	align-items: center;
	.ad-label {
		padding: 0 12px;
		height: 24px;
		line-height: 24px;
	}
	.operator {
		background: #C2C2C2;
		border-radius: 4px 0px 0px 4px;
	}
	.channel {
		background-color: rgba(194,194,194,0.8);
	}
	.delivery {
		background: rgba(194,194,194,0.6);
		border-radius: 0px 4px 4px 0px;
	}
}
.ad-page-top-r {
	display: flex;
	align-items: center;
}

.ad-page-cont-inner {
	display: flex;
}
.ad-page-cont-inner-l {
	margin-right: 24px;
	width: 448px;
}
.order-material {
	position: relative;
	height: 187px;
	/*background-color: #fff;*/
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.order-material-cont {
	width: 100%;
	height: 187px;
	border-radius: 8px;
}
.order-content-duration {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 8px;
	font-size: 12px;
	&.bottom {
		left: 16px;
		bottom: 8px;
		top: auto;
		right: auto;
		flex-direction: row;
		.btn-play {
			margin-bottom: 0;
			margin-right: 6px;
			width: 20px;
			height: 20px;
			border-width: 2px;
			cursor: default;
			.icon-play {
				font-size: 12px;
			}
		}
	}
	.btn-play {
		margin-bottom: 12px;
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 4px solid #fff;
		border-radius: 50%;
		cursor: pointer;
		.icon-play {
			font-size: 30px;
		}
	}
}
.order-material-image {
	width: 100%;
	height: 187px;
	border-radius: 8px;
}
.order-material-default {
	width: 448px;
	height: 187px;
	background-image: url(../../../../../assets/images/ad-exec/bg-default-material.png);
}
.order-material-image-tips {
	position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translateY(-50%);
  .text {
  	margin-bottom: 16px;
  	color: #fff;
  }
}
.order-material-mask {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 8px;
}
.order-material-tips {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 12px;
	.order-material-tips-tit {
		margin-bottom: 8px;
		font-weight: bold;
		font-size: 16px;
	}
}
.order-material-reasons {
	margin-bottom: 8px;
	font-size: 12px;
	color: #fff;
}
.btn-add-material {
	padding: 0 24px;
	height: 40px;
	line-height: 40px;
	background-color: #E2A45A;
	border-radius: 4px;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.btn-opts {
	position: absolute;
	right: 0;
	top: 0;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.btn-more {
	transform: rotate(90deg);
	font-size: 16px;
	color: #fff;
}
.btn-opts-menu {
	position: absolute;
	right: 6px;
	top: 50px;
	background-color: #fff;
	box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.16);
	border-radius: 4px;
	white-space: nowrap;
	&::before {
		content: ' ';
		position: absolute;
		right: 18px;
    top: -10px;
		border: 6px solid transparent;
		border-bottom-color: #fff;
	}
	.btn-opts-menu-item {
		padding: 14px 16px;
		&:not(:last-child) {
			border-bottom: 1px solid #EDEDED;
		}
	}
}

.order-material-requirements {
	margin-top: -10px;
	padding: 10px 0 0 16px;
	display: flex;
	align-items: center;
	height: 42px;
	background-color: #FFF8E8;
	border-radius: 0px 0px 8px 8px;
	font-size: 12px;
	color: #FE8135;
	cursor: pointer;
}
.btn-see-requirements {
	margin-left: 10px;
	font-size: 10px;
	color: #FFCEA0;
	/*transform: rotate(90deg);*/
}
.ad-page-cont-inner-r {
	flex: 1;
}

.ad-item-opts {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.ad-content-tips {
}
.ad-content-status {
	font-weight: bold;
}
.ad-end-date {
	margin: 0 4px;
	color: #FF0032;
}

.ad-delivery-days {
	margin: 0 4px;
	font-weight: bold;
	color: #E2A45A;
}

.ad-close-reason .ad-close-icon {
	margin-right: 6px;
	font-size: 13px;
	color: #FF4D4F;
}

.ad-status {
	margin-left: 16px;
	font-size: 18px;
	font-weight: bold;
	color: #BD7D38;
}

.flex {
	display: flex;
	align-items: center;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}
.delivery-name {
	position: relative;
	margin-left: 24px;
	font-size: 16px;
}

.ad-form-wrap {
	margin-top: 24px;
	position: relative;
}
.btn-edit {
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.icon-pencile {
	margin-right: 10px;
}
.ad-form {
	margin-top: 16px;
	padding: 32px 24px;
	background-color: #FAFAFA;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	display: flex;
	flex-wrap: wrap;
	font-size: 14px;
}
.ad-form-item {
	margin-bottom: 12px;
	display: flex;
	align-items: center;
}
.ad-form-item:not(:last-child) {
	margin-right: 60px;
}
.ad-form-label {
	display: flex;
	align-items: center;
	color: #999;
}
.ad-form-icon {
	margin-right: 10px;
}
.text-days {
	font-size: 12px;
	color: #999;
}
.ad-form-value {
	display: flex;
	align-items: center;
}
.i-crown {
	margin: 0 3px 0 5px;
	color: #fcae50;
}
.ad-form-value-tips {
	margin-left: 10px;
	padding: 0 16px;
	height: 20px;
	line-height: 20px;
	background-color: #eee;
	border-radius: 10px;
	font-size: 12px;
	color: #FB3E63;
}

.delivery-name-ipt {
	width: 600px;
	border-color: transparent;
	border-bottom-color: #E2A45A;
}

.text-deliveryName {
	display: flex;
	align-items: center;
	height: 40px;
}
.btn-pencile {
	margin-left: 10px;
	padding: 0 20px;
	cursor: pointer;
}
.btn-checked {
	position: absolute;
	right: -10px;
	top: 0;
	height: 40px;
	padding: 0 20px;
	font-size: 12px;
	color: #333;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}