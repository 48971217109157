.content {
	padding-bottom: 60px;
	text-align: center;
}
.pic-step-2-wrap {
	margin: 48px auto 40px;
	width: 420px;
	position: relative;
}
.pic-step-2 {
	width: 420px;
	height: 264px;
	background-image: url('../../../../../assets/images/assistant/pic-step-2.png');
}

.tips {
	position: absolute;
	top: 28%;
	right: -20%;
	padding: 10px 13px 10px 26px;
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	color: #666;
}
.tips:before {
	position: absolute;
	top: 50%;
	left: -20px;
	content: ' ';
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-right: 10px solid #fff;
	transform: translateY(-50%);
}

.hide {
	display: none;
}
.checkbox-bar-wrap {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.checkbox-bar {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.checkbox-my-wrap {
	position: relative;
	margin-right: 8px;
}
.checkbox-my {
	width: 16px;
	height: 16px;
	border: 1px solid #999999;
	border-radius: 2px;
}
.checkbox-my.is-checked {
	border: 1px solid  #465870;
	color: #465870;
	background-color: #465870;
}
.checkbox-icon {
	position: absolute;
	left: 3px;
	top: 3px;
	color: #fff;
	font-size: 10px;
}
