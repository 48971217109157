.point {
  background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
  cursor: pointer;
}

.title {
  padding: 24px 0 16px 24px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
  font-weight: bold;
}

.panel {
  padding: 24px;
  height: 170px;
}
.sub-title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.icon-cinema {
  font-size: 14px;
  color: #E2A45A;
  font-weight: normal;
}
.num {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.is-success {
  color: #45C9B8;
}
.content .txt {
  margin-top: 10px;
  font-size: 16px;
}