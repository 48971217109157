.task {
  height: 100%;
}

h3.title {
  padding: 24px 0 16px 36px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
  font-weight: bold;
}

.content {
  height: calc(100% - 59px);
}

.no-task {
  color: #ccc;
}