/* 设置库存 */
.modal-tips {
  margin-bottom: 40px;
  padding: 20px 20px 20px 40px;
  background-color: #F5F5F5;
  color: #999;
}
.modal-tips-tit {
  margin-left: -20px;
  font-weight: bold;
  color: #333;
}
.modal-footer {
  margin: 0 -24px -24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 40px 16px 0;
  background-color: #F5F5F5;
}
.modal-btn {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #EDEDED;
}
.modal-btn:hover,
.modal-btn:focus {
  border-color: #E2A45A;
  color: #333;
}
.modal-btn:not(:last-child) {
  margin-right: 16px;
}
.modal-btn.confirm {
  background-color: #E2A45A;
  border-color: #E2A45A;
  color: #fff;
}
.flex {
  display: flex;
  align-items: center;
}
.slider {
  margin: 0 10px;
  width: 200px;
}