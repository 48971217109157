.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 24px;
  width: 100%;
  height: 72px;
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  color: #333;
  font-size: 16px;
  z-index: 999;
}
.header.static {
  position: static;
}
.left {
  /*cursor: pointer;*/
  display: flex;
  align-items: center;
}
.logo {
  margin-right: 8px;
}
.text-logo {
  font-weight: bold;
  font-size: 32px;
}
.logo-r {
  font-size: 20px;
  margin-left: 16px;
}
.logo-r:before {
  display: inline-block;
  content: ' ';
  width: 1px;
  height: 32px;
  margin-right: 16px;
  background-color: #ccc;
}


.icon-mini {
  margin-right: 8px;
  font-size: 24px;
  color: #ccc;
}

.miniprogram {
  position: relative;
  padding-right: 48px;
  cursor: pointer;
}
.miniprogram.hasborder {
  border-right: 1px solid #ccc;
}

.miniprogram:hover {
  color: #E2A45A;
}

.miniprogram:hover .mini-code {
  display: block;
}

.mini-code {
  display: none;
  position: absolute;
  top: 45px;
  left: 50%;
  padding: 10px 30px 20px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  z-index: 2;
  transform: translateX(-50%);
}
.mini-code h3 {
  color: #666;
}

.assistant {
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.assistant-wrap {
  margin-right: 100px;
  display: flex;
  align-items: center;
}
/*.assistant:hover {
  color: #E2A45A;
}*/
.icon-robot {
  margin-right: 6px;
  /*font-size: 24px;*/
  width: 35px;
  height: 30px;
  background-image: url('../../assets/images/common/logo-assistant.png');
  &.is-disabled {
    filter: grayscale(100%);
  }
}

.sign-in {
  cursor: pointer;
}

.btn-register {
  cursor: pointer;
}

.user {
  position: relative;
  margin-left: 100px;
}
.icon-user {
  font-size: 28px;
}