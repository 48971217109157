.header {
  /*position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;*/
  padding: 0 120px;
  background-color: #040219;
}
.header-inner {
  position: relative;
  /*width: 1680px;
  margin: 0 auto;*/
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 16px;
}
.header-left {
  display: flex;
  align-items: center;
  /*cursor: pointer;*/
}
.logo {
  width: 69px;
  height: 60px;
  background-image: url('../../assets/images/common/logo-assistant.png');
}
.logo-text {
  font-size: 24px;
  color: #fff;
}