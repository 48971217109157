.select-wrap {
  display: flex;
  align-items: center;
  .select {
    width: 180px;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}