.filter-panel {
  margin-bottom: 24px;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  font-size: 12px;
}

.filter-type {
  position: relative;
  display: flex;
}
