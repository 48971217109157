.filter-wrap {
	background-color: #0C0A21;
	display: flex;
	align-items: center;
	justify-content: center;
}
.filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 1232px;
	height: 64px;
	color: #F5F5F5;
	font-size: 14px;
}
.filter-form {
	display: flex;
	align-items: center;
}
.form-item {
	margin-right: 48px;
	display: flex;
	align-items: center;
}
.form-item:last-child {
	margin-right: 0;
}
.form-label {
	margin-right: 10px;
}
.form-label::after {
	content: ':';
}

.form-select {
	width: 200px;
	background-color: transparent;
}

.location-label {
	color: #B1B1B1;
}
.icon-location {
	margin-right: 8px;
	font-size: 16px;
	color: #ccc;
}
.location-current {
	color: #fff;
	cursor: pointer;
}

.opts {
	margin-left: 50px;
}
.btn {
	margin-left: 16px;
	width: 64px;
	height: 24px;
	background-color: #E0E0E0;
	border: 1px solid #E0E0E0;
	box-shadow: 0px 4px 10px 0px rgba(57, 57, 57, 0.5);
	border-radius: 4px;
	font-size: 14px;
	color: #040219;
	font-weight: bold;
	cursor: pointer;
}
.btn:first-child {
	margin-left: 0;
}
.btn:hover {
	opacity: 0.9;
}
.btn:active {
	opacity: 0.8;
}
.btn-reset {
	background-color: #0C0A21;
	color: #E0E0E0;
}