.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}

.ad-info-form {
	margin-top: 18px;
}
.ad-info-form-item {
	display: flex;
	align-items: center;
}
.ad-info-form-item:not(:last-child) {
	margin-bottom: 24px;
}
.ad-info-form-label {
	min-width: 120px;
	color: #666;
}
.ad-info-form-value {
	margin-left: 20px;
	display: flex;
	align-items: center;
}
.btn-copy {
	margin-left: 8px;
	padding: 0 8px;
	height: 24px;
	line-height: 22px;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	cursor: pointer;
}
.role-label {
	margin-left: 8px;
	padding: 0 8px;
	height: 24px;
	line-height: 22px;
	border: 1px solid #ccc;
	border-radius: 12px;
	font-size: 12px;
	color: #666;
}