/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'ifont';  /* Project id 2657595 */
  src: url('//at.alicdn.com/t/c/font_2657595_o1u30dp4ur.woff2?t=1686811399231') format('woff2'),
       url('//at.alicdn.com/t/c/font_2657595_o1u30dp4ur.woff?t=1686811399231') format('woff'),
       url('//at.alicdn.com/t/c/font_2657595_o1u30dp4ur.ttf?t=1686811399231') format('truetype'),
       url('//at.alicdn.com/t/c/font_2657595_o1u30dp4ur.svg?t=1686811399231#ifont') format('svg');
}

.ifont {
  font-family: "ifont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-help:before {
  content: "\e69f";
}

.i-close-o:before {
  content: "\e6ce";
}

.i-download:before {
  content: "\e6cd";
}

.i-question:before {
  content: "\e6cc";
}

.i-location:before {
  content: "\e6cb";
}

.i-robot:before {
  content: "\e6ca";
}

.i-user:before {
  content: "\e6c9";
}

.i-weixin-mini:before {
  content: "\e6c8";
}

.i-tips:before {
  content: "\e6c6";
}

.i-arrow-r:before {
  content: "\e6c5";
}

.i-config:before {
  content: "\e6c3";
}

.i-android:before {
  content: "\e6c1";
}

.i-location-thin:before {
  content: "\e6c0";
}

