.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont {
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #E2A45A;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #E2A45A;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.list-top {
	display: flex;
	align-items: center;
}
.list-tips {
	color: #999;
}
.btn-all {
	margin-left: 16px;
	padding: 0 8px;
	height: 24px;
	line-height: 24px;
	border-radius: 4px;
	border: 1px solid #EEC98F;
	color: #E2A45A;
	cursor: pointer;
}

.list-wrap {
	margin-bottom: 40px;
	display: flex;
	flex-wrap: wrap;
}
.list-item {
	position: relative;
	margin: 16px 0;
	width: calc(50% - 8px);
	display: flex;
	padding: 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	overflow: hidden;
	cursor: pointer;
	line-height: 1.2;
}
.list-item:nth-child(2n-1) {
	margin-right: 16px;
}
.list-item.is-selected {
	background-color: #fff;
	border-color: #E2A45A;
	box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.15);
}
.list-item-l {
	position: relative;
	margin-right: 16px;
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.list-item-r {
	flex: 1;
}
.entity-image {
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.chain-name {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 0 6px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 4px 0 4px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  color: #FEC1A1;
}
.entity-tit {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: bold;
}
.entity-tips {
	margin-bottom: 8px;
	font-size: 12px;
	color: #999;
}
.entity-label {

}
.count-selected {
	margin: 0 2px;
	font-weight: bold;
}
.success {
	color: #E2A45A;
}
.btn-right {
	margin-left: 12px;
	color: #ccc;
	font-size: 12px;
}

.btn-select {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	color: #fff;
	font-size: 12px;
	.icon-select {
		position: absolute;
		right: 0;
		top: -10px;
		z-index: 0;
		font-size: 64px;
		color: #ccc;
		&.is-full {
			color: #E2A45A;
		}
	}
	.text {
		position: relative;
		display: inline-block;
		padding: 8px 8px 0 0;
	}
}

.entity-points {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 0 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #999;
}
.count-total {
	margin-bottom: 4px;
	font-size: 20px;
	font-weight: bold;
	color: #FE8135;
}