.modal-cont {
  display: flex;
  min-height: 450px;
}
.modal-cont-l {
  margin-right: 24px;
  width: 420px;
  .entity-bigimg {
    margin-bottom: 8px;
    width: 420px;
    height: 236px;
    overflow: hidden;
    background-color: #000;
    border-radius: 8px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .entity-img-list {
    position: relative;
    display: flex;
    align-items: center;
    .entity-img-item {
      width: 138px;
      height: 77px;
      overflow: hidden;
      background-color: #000;
      border-radius: 8px;
      cursor: pointer;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
      &:not(:last-child) {
        margin-right: 3px;
      }
      &.is-selected {
        border: 2px solid #E2A45A;
      }
    }

    .btn-left,
    .btn-right {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      transform: rotate(180deg);
      width: 20px;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: #999;
      }
    }
    .btn-right {
      left: auto;
      right: 0;
      transform: none;
    }
  }
}

.modal-cont-r {
  .entity-name {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
  }
  .form-item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    width: 33%;
    &:nth-child(7),
    &:nth-child(8) {
      width: 100%;
    }
  }
  .form-item-label {
    color: #999;
  }
}
.entity-desc-tit {
  margin: 16px 0;
  padding-top: 16px;
  border-top: 1px solid #E6E6E6;
  font-weight: bold;
}
.entity-desc {
  font-size: 12px;
  color: #999;
}
.point-industry-item {
  display: flex;
  flex-wrap: wrap;
  /* padding: 8px 0; */
}
.point-industry-label {
  margin-right: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  background-color: rgba(226, 164, 90, 0.1);
  border-radius: 16px;
  text-align: center;
  color: #E2A45A;
}
.point-industry-values {
  margin-left: -16px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.point-industry-value {
  margin-bottom: 8px;
  margin-left: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #999;
  border-radius: 12px;
  text-align: center;
  color: #666;
}