.icon-dot {
  display: inline-block;
  margin-right: 4px;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
}
.icon-dot.primary {
  background-color: #33A6FF;
}
.icon-dot.warn {
  background-color: #FE8135;
}
.icon-dot.success {
  background-color: #45C9B8;
}
.icon-dot.error {
  background-color: #FF4D4F;
}