html, body {
  background-color: #f5f5f5;
  font-family: 'Microsoft YaHei';
  color: #333;
  font-size: 14px;
}

a, a:hover {
  color: #333;
}

.icon {
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  text-indent: -9999px;
  direction: ltr;
}
/* ad */
.icon-cell {
  width: 12px;
  height: 12px;
  background-image: url(../images/ad-exec/icon-cell.png);
}
.icon-calendar {
  width: 12px;
  height: 12px;
  background-image: url(../images/ad-exec/icon-calendar.png?v=1);
}
.icon-clock {
  width: 12px;
  height: 12px;
  background-image: url(../images/ad-exec/icon-clock.png?v=2);
}
.icon-order {
  width: 12px;
  height: 12px;
  background-image: url(../images/ad-exec/icon-order.png);
}

.L {
  width: 16px;
  height: 18px;
}
.L1 {
  background-image: url(../images/remo/icon-L1.png);
}
.L2 {
  background-image: url(../images/remo/icon-L2.png);
}
.L3 {
  background-image: url(../images/remo/icon-L3.png);
}
.L4 {
  background-image: url(../images/remo/icon-L4.png);
}
.L5 {
  background-image: url(../images/remo/icon-L5.png);
}
.L6 {
  background-image: url(../images/remo/icon-L6.png);
}

.deli {
  background-color:rgba(64,130,240,.1) !important;
  border-color: rgba(64,130,240,1) !important;
  color: #42C8F7 !important;
}
.oper {
  background-color: rgba(106,198,184,.1) !important;
  border-color: rgba(106,198,184,1) !important;
  color: #6AC6B8 !important;
}
.publish {
  background-color:rgba(62, 189, 255, 0.1) !important;
  border-color:rgba(62, 189, 255, 1) !important;
  color: #3ebdff !important;
}
.check {
  background-color:rgba(255, 167, 82, 0.1) !important;
  border-color:rgba(255, 167, 82, 1) !important;
  color: #FFA752 !important;
}
.manager {
  background-color:rgba(255, 139, 61, .1) !important;
  border-color:rgba(255, 139, 61, 1) !important;
  color: #FF8B3D !important;
}

/* 定位 */
.pr { position: relative; }
.pa { position: absolute; }
.pf { position: fixed; }

/* 浮动 */
.fs { float: left; }
.fe { float: right; }

/* 元素显示/隐藏 */
.show { display: block; }
.hide { display: none; }

/* 文本水平对齐方式 */
.tl { text-align: left; }
.tc { text-align: center; }
.tr { text-align: right; }

/* 字体粗细 */
.fb { font-weight: bold; }
.fl { font-weight: lighter; }

/* 单行省略号 */
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}

/* 清除浮动 */
.clearfix {
  content: '';
  display: table;
  clear: both;
}

.dangers {
  background-color: #ff0032;
}

/* flex */
.flex { 
  display: -webkit-flex;
  display: flex; 
}

.wrap { flex-wrap: wrap; }

.row { flex-direction: row; }
.row-reverse { flex-direction: row-reverse; }
.column { flex-direction: column; }
.column-reverse { flex-direction: column-reverse; }

.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-end { justify-content: flex-end; }

.align-start { align-items: flex-start; }
.align-center { align-items: center; }
.align-between { align-content: space-between; }
.align-end { align-items: flex-end; }

.self-start { align-self: flex-start; }
.self-center { align-self: center; }
.self-end { align-self: flex-end; }

/* margin */
.ma0 { margin: 0; }
.ma1 { margin: 4px; }
.ma2 { margin: 8px; }
.ma3 { margin: 12px; }
.ma4 { margin: 16px; }
.ma5 { margin: 20px; }
.ma6 { margin: 24px; }
.ma7 { margin: 28px; }
.ma8 { margin: 32px; }
.ma9 { margin: 36px; }
.ma10 { margin: 40px; }
.ma11 { margin: 44px; }
.ma12 { margin: 48px; }
.ma13 { margin: 52px; }
.ma14 { margin: 56px; }
.ma15 { margin: 60px; }
.ma16 { margin: 64px; }
.ma17 { margin: 68px; }
.ma18 { margin: 72px; }
.ma19 { margin: 76px; }
.ma20 { margin: 80px; }
.ma21 { margin: 84px; }
.ma22 { margin: 88px; }
.ma23 { margin: 92px; }
.ma24 { margin: 96px; }

.mx0 { margin-left: 0; margin-right: 0; }
.mx1 { margin-left: 4px; margin-right: 4px; }
.mx2 { margin-left: 8px; margin-right: 8px; }
.mx3 { margin-left: 12px; margin-right: 12px; }
.mx4 { margin-left: 16px; margin-right: 16px; }
.mx5 { margin-left: 20px; margin-right: 20px; }
.mx6 { margin-left: 24px; margin-right: 24px; }
.mx7 { margin-left: 28px; margin-right: 28px; }
.mx8 { margin-left: 32px; margin-right: 32px; }
.mx9 { margin-left: 36px; margin-right: 36px; }
.mx10 { margin-left: 40px; margin-right: 40px; }
.mx11 { margin-left: 44px; margin-right: 44px; }
.mx12 { margin-left: 48px; margin-right: 48px; }
.mx13 { margin-left: 52px; margin-right: 52px; }
.mx14 { margin-left: 56px; margin-right: 56px; }
.mx15 { margin-left: 60px; margin-right: 60px; }
.mx16 { margin-left: 64px; margin-right: 64px; }
.mx17 { margin-left: 68px; margin-right: 68px; }
.mx18 { margin-left: 72px; margin-right: 72px; }
.mx19 { margin-left: 76px; margin-right: 76px; }
.mx20 { margin-left: 80px; margin-right: 80px; }
.mx21 { margin-left: 84px; margin-right: 84px; }
.mx22 { margin-left: 88px; margin-right: 88px; }
.mx23 { margin-left: 92px; margin-right: 92px; }
.mx24 { margin-left: 96px; margin-right: 96px; }


.my0 { margin-top: 0; margin-bottom: 0; }
.my1 { margin-top: 4px; margin-bottom: 4px; }
.my2 { margin-top: 8px; margin-bottom: 8px; }
.my3 { margin-top: 12px; margin-bottom: 12px; }
.my4 { margin-top: 16px; margin-bottom: 16px; }
.my5 { margin-top: 20px; margin-bottom: 20px; }
.my6 { margin-top: 24px; margin-bottom: 24px; }
.my7 { margin-top: 28px; margin-bottom: 28px; }
.my8 { margin-top: 32px; margin-bottom: 32px; }
.my9 { margin-top: 36px; margin-bottom: 36px; }
.my10 { margin-top: 40px; margin-bottom: 40px; }
.my11 { margin-top: 44px; margin-bottom: 44px; }
.my12 { margin-top: 48px; margin-bottom: 48px; }
.my13 { margin-top: 52px; margin-bottom: 52px; }
.my14 { margin-top: 56px; margin-bottom: 56px; }
.my15 { margin-top: 60px; margin-bottom: 60px; }
.my16 { margin-top: 64px; margin-bottom: 64px; }
.my17 { margin-top: 68px; margin-bottom: 68px; }
.my18 { margin-top: 72px; margin-bottom: 72px; }
.my19 { margin-top: 76px; margin-bottom: 76px; }
.my20 { margin-top: 80px; margin-bottom: 80px; }
.my21 { margin-top: 84px; margin-bottom: 84px; }
.my22 { margin-top: 88px; margin-bottom: 88px; }
.my23 { margin-top: 92px; margin-bottom: 92px; }
.my24 { margin-top: 96px; margin-bottom: 96px; }


.mt0 { margin-top: 0; }
.mt1 { margin-top: 4px; }
.mt2 { margin-top: 8px; }
.mt3 { margin-top: 12px; }
.mt4 { margin-top: 16px; }
.mt5 { margin-top: 20px; }
.mt6 { margin-top: 24px; }
.mt7 { margin-top: 28px; }
.mt8 { margin-top: 32px; }
.mt9 { margin-top: 36px; }
.mt10 { margin-top: 40px; }
.mt11 { margin-top: 44px; }
.mt12 { margin-top: 48px; }
.mt12 { margin-top: 52px; }
.mt13 { margin-top: 56px; }
.mt14 { margin-top: 60px; }
.mt15 { margin-top: 64px; }
.mt16 { margin-top: 68px; }
.mt17 { margin-top: 72px; }
.mt18 { margin-top: 76px; }
.mt19 { margin-top: 80px; }
.mt20 { margin-top: 84px; }
.mt22 { margin-top: 88px; }
.mt23 { margin-top: 92px; }
.mt24 { margin-top: 96px; }


.mb0 { margin-bottom: 0; }
.mb1 { margin-bottom: 4px; }
.mb2 { margin-bottom: 8px; }
.mb3 { margin-bottom: 12px; }
.mb4 { margin-bottom: 16px; }
.mb5 { margin-bottom: 20px; }
.mb6 { margin-bottom: 24px; }
.mb7 { margin-bottom: 28px; }
.mb8 { margin-bottom: 32px; }
.mb9 { margin-bottom: 36px; }
.mb10 { margin-bottom: 40px; }
.mb11 { margin-bottom: 44px; }
.mb12 { margin-bottom: 48px; }
.mb13 { margin-bottom: 52px; }
.mb14 { margin-bottom: 56px; }
.mb15 { margin-bottom: 60px; }
.mb16 { margin-bottom: 64px; }
.mb17 { margin-bottom: 68px; }
.mb18 { margin-bottom: 72px; }
.mb19 { margin-bottom: 76px; }
.mb20 { margin-bottom: 80px; }
.mb21 { margin-bottom: 84px; }
.mb22 { margin-bottom: 88px; }
.mb23 { margin-bottom: 92px; }
.mb24 { margin-bottom: 96px; }


.ml0 { margin-left: 0; }
.ml1 { margin-left: 4px; }
.ml2 { margin-left: 8px; }
.ml3 { margin-left: 12px; }
.ml4 { margin-left: 16px; }
.ml5 { margin-left: 20px; }
.ml6 { margin-left: 24px; }
.ml7 { margin-left: 28px; }
.ml8 { margin-left: 32px; }
.ml9 { margin-left: 36px; }
.ml10 { margin-left: 40px; }
.ml11 { margin-left: 44px; }
.ml12 { margin-left: 48px; }
.ml13 { margin-left: 52px; }
.ml14 { margin-left: 56px; }
.ml15 { margin-left: 60px; }
.ml16 { margin-left: 64px; }
.ml17 { margin-left: 68px; }
.ml18 { margin-left: 72px; }
.ml19 { margin-left: 76px; }
.ml20 { margin-left: 80px; }
.ml21 { margin-left: 84px; }
.ml22 { margin-left: 88px; }
.ml23 { margin-left: 92px; }
.ml24 { margin-left: 96px; }

.mr0 { margin-right: 0; }
.mr1 { margin-right: 4px; }
.mr2 { margin-right: 8px; }
.mr3 { margin-right: 12px; }
.mr4 { margin-right: 16px; }
.mr5 { margin-right: 20px; }
.mr6 { margin-right: 24px; }
.mr7 { margin-right: 28px; }
.mr8 { margin-right: 32px; }
.mr9 { margin-right: 36px; }
.mr10 { margin-right: 40px; }
.mr11 { margin-right: 44px; }
.mr12 { margin-right: 48px; }
.mr13 { margin-right: 52px; }
.mr14 { margin-right: 56px; }
.mr15 { margin-right: 60px; }
.mr16 { margin-right: 64px; }
.mr17 { margin-right: 68px; }
.mr18 { margin-right: 72px; }
.mr19 { margin-right: 76px; }
.mr20 { margin-right: 80px; }
.mr21 { margin-right: 84px; }
.mr22 { margin-right: 88px; }
.mr23 { margin-right: 92px; }
.mr24 { margin-right: 96px; }

/* padding */
.pa0 { padding: 0; }
.pa1 { padding: 4px; }
.pa2 { padding: 8px; }
.pa3 { padding: 12px; }
.pa4 { padding: 16px; }
.pa5 { padding: 20px; }
.pa6 { padding: 24px; }
.pa7 { padding: 28px; }
.pa8 { padding: 32px; }
.pa9 { padding: 36px; }
.pa10 { padding: 40px; }
.pa11 { padding: 44px; }
.pa12 { padding: 48px; }
.pa13 { padding: 52px; }
.pa14 { padding: 56px; }
.pa15 { padding: 60px; }
.pa16 { padding: 64px; }
.pa17 { padding: 68px; }
.pa18 { padding: 72px; }
.pa19 { padding: 76px; }
.pa20 { padding: 80px; }
.pa21 { padding: 84px; }
.pa22 { padding: 88px; }
.pa23 { padding: 92px; }
.pa24 { padding: 96px; }


.px0 { padding-left: 0; padding-right: 0; }
.px1 { padding-left: 4px; padding-right: 4px; }
.px2 { padding-left: 8px; padding-right: 8px; }
.px3 { padding-left: 12px; padding-right: 12px; }
.px4 { padding-left: 16px; padding-right: 16px; }
.px5 { padding-left: 20px; padding-right: 20px; }
.px6 { padding-left: 24px; padding-right: 24px; }
.px7 { padding-left: 28px; padding-right: 28px; }
.px8 { padding-left: 32px; padding-right: 32px; }
.px9 { padding-left: 36px; padding-right: 36px; }
.px10 { padding-left: 40px; padding-right: 40px; }
.px11 { padding-left: 44px; padding-right: 44px; }
.px12 { padding-left: 48px; padding-right: 48px; }
.px13 { padding-left: 52px; padding-right: 52px; }
.px14 { padding-left: 56px; padding-right: 56px; }
.px15 { padding-left: 60px; padding-right: 60px; }
.px16 { padding-left: 64px; padding-right: 64px; }
.px17 { padding-left: 68px; padding-right: 68px; }
.px18 { padding-left: 72px; padding-right: 72px; }
.px19 { padding-left: 76px; padding-right: 76px; }
.px20 { padding-left: 80px; padding-right: 80px; }
.px21 { padding-left: 84px; padding-right: 84px; }
.px22 { padding-left: 88px; padding-right: 88px; }
.px23 { padding-left: 92px; padding-right: 92px; }
.px24 { padding-left: 96px; padding-right: 96px; }


.py0 { padding-top: 0; padding-bottom: 0; }
.py1 { padding-top: 4px; padding-bottom: 4px; }
.py2 { padding-top: 8px; padding-bottom: 8px; }
.py3 { padding-top: 12px; padding-bottom: 12px; }
.py4 { padding-top: 16px; padding-bottom: 16px; }
.py5 { padding-top: 20px; padding-bottom: 20px; }
.py6 { padding-top: 24px; padding-bottom: 24px; }
.py7 { padding-top: 28px; padding-bottom: 28px; }
.py8 { padding-top: 32px; padding-bottom: 32px; }
.py9 { padding-top: 36px; padding-bottom: 36px; }
.py10 { padding-top: 40px; padding-bottom: 40px; }
.py11 { padding-top: 44px; padding-bottom: 44px; }
.py12 { padding-top: 48px; padding-bottom: 48px; }
.py13 { padding-top: 52px; padding-bottom: 52px; }
.py14 { padding-top: 56px; padding-bottom: 56px; }
.py15 { padding-top: 60px; padding-bottom: 60px; }
.py16 { padding-top: 64px; padding-bottom: 64px; }
.py17 { padding-top: 68px; padding-bottom: 68px; }
.py18 { padding-top: 72px; padding-bottom: 72px; }
.py19 { padding-top: 76px; padding-bottom: 76px; }
.py20 { padding-top: 80px; padding-bottom: 80px; }
.py21 { padding-top: 84px; padding-bottom: 84px; }
.py22 { padding-top: 88px; padding-bottom: 88px; }
.py23 { padding-top: 92px; padding-bottom: 92px; }
.py25 { padding-top: 96px; padding-bottom: 96px; }

.pt0 { padding-top: 0; }
.pt1 { padding-top: 4px; }
.pt2 { padding-top: 8px; }
.pt3 { padding-top: 12px; }
.pt4 { padding-top: 16px; }
.pt5 { padding-top: 20px; }
.pt6 { padding-top: 24px; }
.pt7 { padding-top: 28px; }
.pt8 { padding-top: 32px; }
.pt9 { padding-top: 36px; }
.pt10 { padding-top: 40px; }
.pt11 { padding-top: 44px; }
.pt12 { padding-top: 48px; }
.pt13 { padding-top: 52px; }
.pt14 { padding-top: 56px; }
.pt15 { padding-top: 60px; }
.pt16 { padding-top: 64px; }
.pt17 { padding-top: 68px; }
.pt18 { padding-top: 72px; }
.pt19 { padding-top: 76px; }
.pt20 { padding-top: 80px; }
.pt21 { padding-top: 84px; }
.pt22 { padding-top: 88px; }
.pt23 { padding-top: 92px; }
.pt24 { padding-top: 96px; }

.pb0 { padding-bottom: 0; }
.pb1 { padding-bottom: 4px; }
.pb2 { padding-bottom: 8px; }
.pb3 { padding-bottom: 12px; }
.pb4 { padding-bottom: 16px; }
.pb5 { padding-bottom: 20px; }
.pb6 { padding-bottom: 24px; }
.pb7 { padding-bottom: 28px; }
.pb8 { padding-bottom: 32px; }
.pb9 { padding-bottom: 36px; }
.pb10 { padding-bottom: 40px; }
.pb11 { padding-bottom: 44px; }
.pb12 { padding-bottom: 48px; }
.pb13 { padding-bottom: 52px; }
.pb14 { padding-bottom: 56px; }
.pb15 { padding-bottom: 60px; }
.pb16 { padding-bottom: 64px; }
.pb17 { padding-bottom: 68px; }
.pb18 { padding-bottom: 72px; }
.pb19 { padding-bottom: 76px; }
.pb20 { padding-bottom: 80px; }
.pb21 { padding-bottom: 84px; }
.pb22 { padding-bottom: 88px; }
.pb23 { padding-bottom: 92px; }
.pb24 { padding-bottom: 96px; }

.pl0 { padding-left: 0; }
.pl1 { padding-left: 4px; }
.pl2 { padding-left: 8px; }
.pl3 { padding-left: 12px; }
.pl4 { padding-left: 16px; }
.pl5 { padding-left: 20px; }
.pl6 { padding-left: 24px; }
.pl7 { padding-left: 28px; }
.pl8 { padding-left: 32px; }
.pl9 { padding-left: 36px; }
.pl10 { padding-left: 40px; }
.pl11 { padding-left: 44px; }
.pl12 { padding-left: 48px; }
.pl13 { padding-left: 52px; }
.pl14 { padding-left: 56px; }
.pl15 { padding-left: 60px; }
.pl16 { padding-left: 64px; }
.pl17 { padding-left: 68px; }
.pl18 { padding-left: 72px; }
.pl19 { padding-left: 76px; }
.pl20 { padding-left: 80px; }
.pl21 { padding-left: 84px; }
.pl22 { padding-left: 88px; }
.pl23 { padding-left: 92px; }
.pl24 { padding-left: 96px; }

.pr0 { padding-right: 0; }
.pr1 { padding-right: 4px; }
.pr2 { padding-right: 8px; }
.pr3 { padding-right: 12px; }
.pr4 { padding-right: 16px; }
.pr5 { padding-right: 20px; }
.pr6 { padding-right: 24px; }
.pr7 { padding-right: 28px; }
.pr8 { padding-right: 32px; }
.pr9 { padding-right: 36px; }
.pr10 { padding-right: 40px; }
.pr11 { padding-right: 44px; }
.pr12 { padding-right: 48px; }
.pr13 { padding-right: 52px; }
.pr14 { padding-right: 56px; }
.pr15 { padding-right: 60px; }
.pr16 { padding-right: 64px; }
.pr17 { padding-right: 68px; }
.pr18 { padding-right: 72px; }
.pr19 { padding-right: 76px; }
.pr20 { padding-right: 80px; }
.pr21 { padding-right: 84px; }
.pr22 { padding-right: 88px; }
.pr23 { padding-right: 92px; }
.pr24 { padding-right: 96px; }

/* font size */
.fs12 { font-size: 12px; }
.fs14 { font-size: 14px; }
.fs16 { font-size: 16px; }
.fs18 { font-size: 18px; }
.fs20 { font-size: 20px; }
.fs22 { font-size: 22px; }
.fs24 { font-size: 24px; }
.fs26 { font-size: 26px; }
.fs28 { font-size: 28px; }
.fs30 { font-size: 30px; }
.fs32 { font-size: 32px; }
.fs34 { font-size: 34px; }
.fs36 { font-size: 36px; }
.fs38 { font-size: 38px; }
.fs40 { font-size: 40px; }
.fs42 { font-size: 42px; }
.fs44 { font-size: 44px; }
.fs46 { font-size: 46px; }
.fs48 { font-size: 48px; }
.fs50 { font-size: 50px; }
.fs52 { font-size: 52px; }
.fs54 { font-size: 54px; }
.fs56 { font-size: 56px; }
.fs58 { font-size: 58px; }


.text-warning {
  color: #FD8637;
}
