.entity-cont {
  box-sizing: border-box;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 1;
  font-size: 14px;
}
.btn-table-link {
  color: #E2A45A;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.paging {
  margin-top: 24px;
}