/* 无记录 */
.no-record {
  min-height: 500px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  color: #ccc;
}
.bg-no-record {
  margin-bottom: 16px;
  width: 171px;
  height: 139px;
  background: url('../../assets/images/common/bg-norecord.png') no-repeat center;
  background-size: 100%;
}