.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}

.ad-page-bottom {
	padding: 16px 24px;
	background-color: #fff;
	border-radius: 8px;
	height: 72px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
}
.ad-price-label {
	margin-right: 10px;
	padding: 0 8px;
	height: 20px;
	line-height: 18px;
	background-color: #FFF8E8;	
	border-radius: 12px 12px 12px 0px;
	border: 1px solid #EEC98F;
	color: #666;
	font-size: 12px;
}
.ad-page-bottom-label {
	margin-right: 8px;
	font-size: 16px;
	font-weight: bold;
}
.text-price {
	margin-left: 40px;
	margin-right: 8px;
	font-size: 16px;
	color: #FE8135;
}
.text-price-int {
	font-size: 24px;
}