.technic-price-total {
  margin-bottom: 20px;
  color: #FE8135;
  font-size: 24px;
  font-weight: bold;
  .text-int {
    font-size: 14px;
  }
}
.technic-price-list {
  margin-bottom: 24px;
  border-bottom: 1px solid #EDEDED;
  line-height: 1;
}
.technic-price-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .technic-price-item-label {
    margin-right: 24px;
    width: 250px;
    display: flex;
    align-items: center;
    color: #999;
    .icon-question {
      margin-left: 5px;
      font-size: 14px;
      color: #ccc;
    }
  }
  .total {
    font-weight: bold;
  }
  .special {
    color: #FE8135;
  }
}

.rule-tit {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.rule-tips {
  color: #666;
}

.modal-cont {
  margin-top: 30px;
  background-color: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  height: 45px;
  overflow: hidden;
  transition: all 0.5s;
  font-size: 14px;
}
.modal-cont-tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid #E6E6E6;
  font-size: 14px;
}
.text-more {
  font-size: 14px;
  color: #999;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-toggle {
  margin-left: 6px;
}
.btn-toggle.top {
  transform: rotate(180deg);
}
.bold {
  font-weight: bold;
}
.rule-cont {
  padding: 24px;
}
.tit {
  margin-bottom: 24px;
}
.mb24 {
  margin-bottom: 24px;
}

.technic-rules-list {
  margin: 12px 0;
  width: 514px;
  font-size: 12px;
  line-height: 1;
}
.technic-rules-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #DBDBDB;
  background-color: #fff;
  color: #666;
}
.technic-rules-item-top-text {
  flex: 1;
  padding-left: 24px;
}
.technic-rules-item {
  padding: 12px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DBDBDB;
}
.technic-rules-item-label {
  flex: 1;
  color: #666;
  padding-left: 24px;
}
.technic-rules-item-value {
  flex: 1;
  padding-left: 24px;
}