.welcome-wrap {
	margin: 10px auto;
  width: 1232px;
  height: calc(100% - 120px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.welcome-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pic-welcome {
	margin-bottom: 20px;
	width: 402px;
	height: 402px;
	background-image: url('../../../assets/images/common/welcome.png');
}

.welcome-tit {
  margin-top: 48px;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: bold;
}
.welcome-tips {
  color: #666;
}
.text-important {
	color: #FD8637;
}
.mini-code {
	margin: 0 auto;
	width: 240px;
	height: 240px;
	background-image: url('../../../assets/images/common/mini-code.jpg?imageMogr2/thumbnail/168x');
}