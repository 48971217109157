.filter-wrap {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	.form-item {
		margin-right: 20px;
		display: flex;
		align-items: center;
	}
}

.receipt-item {
	position: relative;
	padding: 0 0 24px 20px;
  display: flex;
	&::before {
		content: ' ';
		position: absolute;
		left: 0;
		top: 6px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border: 1px solid #999;
		border-radius: 50%;
	}
	&:not(:last-child) {
		&::after {
			content: ' ';
			position: absolute;
			left: 4px;
			top: 18px;
			bottom: -8px;
			width: 1px;
			background-color: #ccc;
		}
	}
}

.entity-name {
  width: 350px;
  font-weight: bold;
}

.receipt-tit {
  width: 200px;
  .date {
    margin-right: 12px;
  }
  .receipt-label {
    display: inline-block;
    padding: 0 8px;
    border: 1px solid #ccc;
    line-height: 18px;
    border-radius: 10px;
  }
  .count {
    margin-top: 6px;
    color: #999;
  }
}
.receipt-no-record {
  border-radius: 4px;
  width: 112px;
  height: 63px;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FF4D4F;
}

.receipt-cont-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.receipt-tips {
  margin: 0 10px 10px 0;
  width: 112px;
  height: 63px;
  background-color: #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  .receipt-image {
    border: 1px solid #FFF6EB;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: contain;
  }
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    line-height: 1.2;
    .icon-play {
      width: 16px;
      height: 16px;
      border: 1px solid #fff;
      border-radius: 50%;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}