.content-cont {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
}

/*过滤*/
.filter-panel {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.filter-type {
  display: flex;
  align-items: center;
}
.filter-select {
  margin-right: 16px;
  width: 170px;
  border: 1px solid #CCC;
  border-radius: 4px;
}
.search-opts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  border: 1px solid #CCC;
  border-radius: 4px;
}
.search-opts .imediafont {
  height: 30px;
  padding: 0 16px 0 6px;
  line-height: 30px;
  font-size: 18px;
  color: #999;
  cursor: pointer;
}

.content-storage-wrap {
  display: flex;
}
.content-storage {
  margin-right: 48px;
  width: 240px;
}
.content-storage-text {
  position: relative;
  height: 32rpx;
  line-height: 32px;
  font-size: 12px;
  color: #999;
}
.content-storage-used {
  color: #666;
}
.content-storage-progress {
  position: absolute;
  bottom: -6px;
}
.content-storage-tips {
  margin-top: 10px;
  color: #f00;
}

/*列表*/
.content-table td {
  vertical-align: middle;
}
.video-wrap {
  position: relative;
  width: 160px;
  height: 67px;
  border-radius: 4px;
}
.cursor-pointer {
  cursor: pointer;
}
.video-result-tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
}
.video-image {
  width: 160px;
  height: 67px;
  background-color: #000;
  border-radius: 4px;
  object-fit: contain;
}
.video-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.8;
}
.video-mask:hover {
  opacity: 1;
}
/*.video-mask:hover {
  background-color: transparent;
}
.video-mask:hover .video-btn-icon,
.video-mask:hover .video-duration {
  display: none;
}*/
.video-btn-icon {
  font-size: 21px;
  color: #fff;
}
.video-duration {
  margin-top: 2px;
  font-size: 12px;
  color: #fff;
}

.btn-opt {
  color: #E2A45A;
  cursor: pointer;
}
.btn-opt:hover {
  opacity: 0.9;
}

.paging {
  margin-top: 40px;
}

/* 视频播放弹窗 */
.video-modal {
  width: 700px;
  height: 500px;
  background-color: #000;
}

.btn-confirm {
  background-color: #fff !important;
  border-color: #E2A45A !important; 
  border-radius: 4px;
  color: #E2A45A !important;
  text-shadow: none;
  border-radius: 4px;
}
.btn-confirm:hover {
  opacity: 0.8;
}
.btn-confirm[disabled],
.btn-confirm[disabled]:hover {
  border-color: #E6E6E6 !important; 
  background-color: #F5F5F5 !important;
  color: #ccc !important;
}
.btn-confirm:not(:last-child) {
  margin-right: 20px;
}

/* 无记录 */
/* 无记录 */
.no-record {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  color: #999;
}
.bg-no-record {
  margin-bottom: 16px;
  width: 110px;
  height: 98px;
  background: url('../../../../assets/images/common/bg-norecord.png') no-repeat center;
  background-size: 100%;
}