.ad-page {
	position: relative;
	.ad-create-type {
		display: inline-block;
		padding: 0 24px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid #ccc;
		font-size: 16px;
		font-weight: bold;
		line-height: 38px;
	}
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont {
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #E2A45A;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #E2A45A;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.tabs {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #E6E6E6;
}
.tab-item {
	position: relative;
	margin-right: 4px;
	padding: 0 24px;
	border: 1px solid #E6E6E6;
	border-bottom: none;
	height: 45px;
	line-height: 45px;
	background-color: #FAFAFA;
	border-radius: 8px 8px 0px 0px;
	cursor: pointer;
}
.tab-item.is-selected {
	margin-bottom: -1px;
	height: 46px;
	background-color: #fff;
	font-weight: bold;
	color: #E2A45A;
}
.subtabs {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
}
.subtab-item {
	padding: 0 40px;
	border: 1px solid #E6E6E6;
	height: 35px;
	line-height: 35px;
	background-color: #FAFAFA;
	font-size: 14px;
}
.subtab-item:not(:first-child) {
	border-left: none;
}
.subtab-item:first-child {
	border-radius: 8px 0px 0px 8px;
}
.subtab-item:last-child {
	border-radius: 0px 8px 8px 0px;
}
.subtab-item.is-selected {
	background-color: #fff;
	font-weight: bold;
	color: #E2A45A;
}