.point-header-wrap {
	position: relative;
	height: 320px;
	background: #2C2B33 url('../../../../assets/images/assistant/bg.png') center top no-repeat;
}
.point-header {
	margin: 0 auto;
	padding: 24px 0;
	position: relative;
	width: 1232px;
	text-align: center;
	color: #ccc;
	font-size: 16px;
}
.icon-eye {
	display: inline-block;
	margin-right: 4px;
	width: 24px;
	height: 24px;
	background-image: url('../../../../assets/images/assistant/icon-eye.png');
}
.btn-help {
	position: absolute;
	right: 0;
	top: 20px;
	cursor: pointer;
	color: #ccc;
}
.btn-help:hover {
	color: #ccc;
}
.entity-info {
	margin: 56px 0 48px;
}
.entity-name {
	margin-bottom: 24px;
	font-weight: bold;
	font-size: 32px;
	color: #fff;
}
.icon-location-thin {
	margin-right: 10px;
	font-size: 18px;
	color: #fff;
	opacity: 0.5;
}
.entity-address {
	font-size: 16px;
	color: #bbb;
}


.point-btn-item {
	margin-right: 24px;
	width: 128px;
	height: 40px;
	line-height: 40px;
	background-color: #2C2B33;
	border: 1px solid #999999;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	font-size: 16px;
	color: #EDEDED;
	cursor: pointer;
	text-align: center;
}
.point-btn-item:last-child {
	margin-right: 0;
}
.point-btn-item.is-disabled {
	opacity: 0.5;
	cursor: default;
}

.point-links-wrap {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 16px 0;
	background-color: rgba(255, 255, 255, 0.03);
}
.point-links {
	width: 1232px;
  margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 16px;
	color: #ccc;
}
.point-link-item {
	margin-left: 40px;
	cursor: pointer;
	font-weight: bold;
}
.point-link-item:first-child {
	margin-left: 0;
}
.icon-question {
	margin-left: 5px;
	font-size: 14px;
	color: #666;
	font-weight: normal;
}
.icon-config {
	margin-right: 5px;
	font-size: 18px;
}

.point-cont {
	position: relative;
	padding-top: 24px;
	width: 1232px;
	margin: 0 auto;
	font-size: 16px;
}
.point-top-tips {
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-bottom: 16px;
}
.icon-tips {
	margin-right: 10px;
	font-size: 20px;
	color: #FF3434;
}
.btn-active {
	margin-left: 6px;
	padding: 0 15px;
	height: 40px;
	line-height: 40px;
	background-color: #FF7C35;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
}

.point-list {
	display: flex;
	flex-wrap: wrap;
	margin-top: 8px;
}
.point-item {
	margin: 0 25px 24px 0;
	padding: 32px 24px;
	width: 394px;
	height: 118px;
	background-color: #fff;
	/*box-shadow: 0px 2px 6px 0px rgba(20, 19, 19, 0.1);*/
	border-radius: 8px;
}
.point-item:nth-child(3n) {
	margin-right: 0;
}
.point-info {
	position: relative;
	flex: 1;
}
.point-name {
	margin-bottom: 16px;
	font-size: 24px;
	font-weight: bold;
}
.point-number {
	color: #999;
}
.point-status {
	position: absolute;
	right: 0;
	top: 10px;
	display: flex;
	align-items: center;
}
.point-status-label {
	margin-right: 8px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
}
.point-status-label.online {
	background-color: #14C9C9;
}
.point-status-label.offline {
	background-color: #999;
}
.point-status-label.notactive {
	background-color: #FE8135;
}