.modal-top {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #EDEDED;
  line-height: 1;
}
.point-name {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.point-name-text {
  margin-right: 18px;
  font-size: 20px;
  font-weight: bold;
}
.entity-name {
  color: #666;
}
.rate-list {
  display: flex;
  align-items: center;
}
.rate-item {
  font-size: 16px;
  color: #FE8135;
}

.stock-form-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.stock-form-label {
  color: #999;
}
.halt-list {
  display: flex;
  flex-wrap: wrap;
}
.halt-item {
  display: flex;
  align-items: center;
  height: 20px;
  background-color: #f5f5f5;
  padding: 0 16px;
  border-radius: 12px;
  margin-left: 16px;
  font-size: 12px;
  color: #666;
}
.icon-halt {
  position: absolute;
  right: 0;
  bottom: -10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  text-indent: -9999px;
  direction: ltr;
  width: 40px;
  height: 40px;
  background-image: url(../../../../../assets/images/remo/icon-halt.png);
}
.calendar-cell {
  margin: 0 2px;
  height: 80px;
  border-top: 1px solid #E6E6E6;
  &.is-today {
    border-top-color: #E2A45A;
  }
}
.calendar-delivery-bg {
  background-color: #FFF8E8;
  text-align: center;
  font-weight: bold;
}
.calendar-date {
  padding: 7px 14px 0 0;
  text-align: right;
  font-weight: normal;
}