.elf {
  border-radius: 8px;
  overflow: hidden;
}

.top {
  height: 160px;
  background-color: #f5f5f5;
}

.top ul {
  position: relative;
  padding-top: 30px;
  margin: 0 auto;
  width: 80%;
}

.top span.line {
  position: absolute;
  left: 50%;
  bottom: 58px;
  width: calc(100% - 150px);
  height: 2px;
  background-color: #999;
  transform: translate(-50%);
}

.top span.water {
  display: block;
  width: 0;
  height: 100%;
  background-color: #E2A45A;
}

.top .icon {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #999;
  z-index: 1;
}

.top .icon.active {
  background-color: #E2A45A;
}

.top .circle {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #333;
  border-radius: 50%;
}

.bottom {
  position: relative;
  height: 65px;
  background-color: #828FA0;
}

.bottom .down-arrow {
  position: absolute;
  content: '';
  top: -6px;
  left: -15px;
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
  transform: rotate(45deg);
}

.bottom .status {
  color: #fff;
}

.bottom .status>div {
  width: 200px;
}

.bottom .status .step1 {
  margin-left: 65px;
}

.bottom .status .step2 {
  margin-left: 90px;
}

.bottom .status .step3 {
  margin-left: 130px;
}

.bottom .btn {
  width: 112px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  border: 1px solid #e2a45a;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(26, 33, 43, .6);
  color: #e2a45a;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}