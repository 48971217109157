.help-cont-tit {
	margin: 30px 0 15px;
	font-weight: bold;
	font-size: 18px;
}
.help-pa {
	padding-left: 30px;
}
.help-important {
	padding: 8px 0 8px 20px;
	font-weight: bold;
	font-size: 14px;
	color: #333 !important;
}