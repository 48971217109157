.desserts {
  position: relative;
  height: calc(100% - 234px);
}

.desserts .top {
  background-color: #fafafa;
}

.desserts .cards {
  height: calc(100% - 117px);
  background-color: #fafafa;
  overflow-y: scroll;
}

.desserts ul.list {
  position: relative;
  z-index: 0;
}

.desserts ul.list li.card {
  position: relative;
  margin-bottom: 20px;
  width: 240px;
  height: 255px;
  border-radius: 8px;
  background-color: #191b1e;
  overflow: hidden;
}

.card-content {
  width: inherit;
  height: inherit;
  overflow-y: scroll;
}

span.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 240px;
  height: 255px;
  background-color: rgba(255, 255, 255, .3);
}

.card-content::-webkit-scrollbar {
  width: 0;
}

li.card {
  color: #666;
  box-shadow: 0px 4px 10px 0px rgba(20, 19, 19, 0.5);
  cursor: pointer;
  overflow: hidden;
}

.gray-selected {
  border: 2px solid #383C42;
}

.selected {
  border: 2px solid #E2A45A;
}

li.card h3.title {
  color: #fff;
}

li.card ul.content {
  position: relative;
  width: 224px;
  border-radius: 6px;
  background-color: #212327;
  overflow: hidden;
}

li.card ul.content li {
  position: relative;
  margin-bottom: 3px;
  font-size: 12px;
  color: #666;
  background-color: rgba(40, 43, 47, .5);
}

li.card ul.content li.selected {
  background-color: #101214;
  color: #E2A45A;
  border: none;
}

li.card ul.content li.selected i.index {
  border-color: #E2A45A;
}

li.card ul.content li.selected span.sec {
  color: #fff;
}

li.card ul.content li i.index {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 13px;
  border: 1px solid #666;
  border-radius: 50%;
}

li.card ul.content li.selected span.status {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 6px;
  background-color: #36C8CD;
  color: #101214;
}

li.card ul.content li.selected span.dangers {
  background-color: #FB3E63;
}

.desserts ul.list li.card i.check {
  position: absolute;
  top: -8px;
  right: -4px;
}

.desserts .bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
}






.submit-report-dialog header {
  background-color: #f5f5f5;
}

.submit-report-dialog footer {
  border-top: 1px solid #f5f5f5;
}