.cont {
  margin: 64px auto;
  width: 786px;
}
.cont-tit {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.cont-tit-number {
  margin: 4px;
  color: #FE8135;
}
.item {
  position: relative;
  padding: 32px 40px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #EDEDED;
  line-height: 1.2;
  cursor: pointer;
}
.company-name {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
}
.company-id {
  font-size: 16px;
  color: #999;
}
.status {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999;
}
.icon-status {
  margin-right: 10px;
  font-size: 16px;
  color: #FE8135;
}
.icon-more {
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 20px;
  color: #ccc;
}