.modal-cont {
  min-height: 450px;
  .stoke-select {
    width: 200px;
  }
  .entity-name {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .entity-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .entity-top-l {
    display: flex;
    align-items: center;
  }
  .entity-top-r {
    display: flex;
    align-items: center;
  }
  .entity-tips-item {
    margin-right: 80px;
    display: flex;
    align-items: center;
  }
  .entity-tips-icon {
    margin-right: 8px;
    display: inline-block;
    width: 32px;
    height: 16px;
    .entity-tips-item &:nth-child(1) {
      background-color: #FFE8E9;
    }
    .entity-tips-item:nth-child(2) & {
      background-color: #FFF8E8;
    }
    .entity-tips-item:nth-child(3) & {
      background-color: #E8FFFB;
    }
    .entity-tips-item:nth-child(4) & {
      background-color: #E9E8FF;
    }
  }

  .echarts-panel {
    margin-right: 50px;
    height: 550px;
  }
}

