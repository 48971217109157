.issue-dialog {
  height: inherit;
}

.issue-dialog header {
  height: 48px;
  background-color: #f5f5f5;
}

.main {
  height: calc(100% - 48px);
}

.issue-dialog span.tips {
  position: relative;
}

.issue-dialog span.tips:hover ul.tips-ul {
  display: block;
}

ul.tips-ul {
  display: none;
  position: absolute;
  top: 32px;
  width: 577px;
  background-color: #F5F5F5;
  box-shadow: 0px 8px 20px 0px rgba(57, 57, 57, 0.3);
  border-radius: 0px 0px 16px 16px;
  color: #393939;
  font-size: 12px;
  font-weight: normal;
  z-index: 1;
}

ul.tips-ul::after {
  position: absolute;
  content: ' ';
  left: 100px;
  top: -6px;
  width: 12px;
  height: 12px;
  background-color: #F5F5F5;
  transform: rotate(45deg);
}

ul.tips-ul h3 {
  font-size: 14px;
  font-weight: bold;
}