.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #E2A45A;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #E2A45A;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.ad-page-cont-inner {
	margin-bottom: 24px;
	position: relative;
	display: flex;
}

.ad-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ad-form-item {
	display: flex;
	align-items: center;
}
.ad-form-item:not(:last-child) {
	margin-right: 60px;
}
.ad-form-label {
	display: flex;
	align-items: center;
	color: #999;
}
.ad-form-icon {
	margin-right: 10px;
}
.text-days {
	font-size: 12px;
	color: #999;
}
.ad-form-value {
	display: flex;
	align-items: center;
}

.report-list {
	margin: 24px 0;
}
.table-cell {
	display: flex;
	align-items: center;
}
.text-price-number {
	font-weight: bold;
}
.btn-table-link {
	color: #E2A45A;
	cursor: pointer;
}
.success {
	color: #45C9B8;
}
.warning {
	color: #FE8135;
}
.gray {
	color: #999;
}
.report-tips-label {
	margin-left: 8px;
	padding: 0 8px;
	height: 20px;
	line-height: 18px;
	background-color: #FFF8E8;
	border-radius: 12px 12px 12px 0px;
	border: 1px solid #EEC98F;
	font-size: 12px;
	color: #666;
	&.is-important {
		background-color: rgba(251, 62, 99, 0.05);
		border-color: rgba(251, 62, 99, 0.05);
    color: #FB3E63;
	}
}