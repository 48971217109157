.company-home {
  padding: 16px 0;
}
.company-home-tit {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: bold;
}
.company-cont {
  margin: 16px 0;
  display: flex;
}
.company-cont-t {
  display: flex;
}
.company-cont-l {
  flex: 2.5;
  display: flex;
}
.box {
  background-color: #fff;
  border-radius: 8px;
}
.broadcast {
  height: 416px;
}
.company-cont-r {
  margin-left: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.company-cont-r.no-bg {
  background-color: transparent;
}
.company-cont-r-t {
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 8px;
}
.company-cont-l-l {
  flex: 1;
}
.company-cont-l-r {
  margin-left: 16px;
  flex: 1;
}
.mt {
  margin-top: 16px;
}