.nail {
  display: inline-block;
  /*width: 72px;*/
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #ccc;
  border-radius: 12px;
  /*background: rgba(62, 189, 255, 0.1);*/
  /*color: #3EBDFF;*/
  font-size: 12px;
  text-align: center;
}