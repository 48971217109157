.personal-home {
  padding: 16px 0;
}
.personal-home-tit {
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: bold;
}
.personal-cont {
  margin: 16px 0;
  display: flex;
}
.personal-cont-t {
  display: flex;
}
.personal-cont-l {
  flex: 2;
  display: flex;
}
.personal-cont-r {
  margin-left: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.personal-cont-item {
  margin-right: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
    flex: 1;
  }
  &.spec {
    margin-left: 16px;
  }
}
.mt {
  margin-top: 16px;
}
.mb {
  margin-bottom: 16px;
}