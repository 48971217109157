.steps-wrap {
	margin-bottom: 40px;
	padding: 28px 0;
	background-color: #F5F5F5;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.steps {
	position: relative;
	display: flex;
}
.steps:before {
	content: ' ';
  position: absolute;
  left: 50%;
  top: 20px;
  height: 2px;
  width: 240px;
  transform: translateX(-50%);
  background-color: #E6E6E6;
}
.step-item {
	margin: 0 80px;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.step-index {
	margin-bottom: 8px;
	width: 32px;
	height: 32px;
	background-color: #EDEDED;
	border-radius: 50%;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
}
.step-item.is-selected .step-index {
	background-color: #FE8135;
	color: #fff;
}
.step-text {
	font-size: 20px;
	font-weight: bold;
}

.steps-cont {
	padding: 0 40px;
}
.steps-cont-item {
	margin-bottom: 40px;
	display: flex;
	align-items: flex-start;
}
.steps-cont-item-l {
	margin-right: 24px;
	font-size: 18px;
	font-weight: bold;
}
.steps-cont-item-tit {
	margin-bottom: 16px;
	font-size: 18px;
	font-weight: bold;
}
.btn-download {
	display: inline-block;
	margin-left: 0;
	text-align: center;
}
.btn-download:hover {
	color: #fff;
}
.steps-cont-item-tips {
	color: #666;
}
.steps-cont-item-text {
	margin-bottom: 16px;
	display: flex;
}
.pic-download {
	width: 440px;
	height: 247px;
}
.pic-download:not(:last-child) {
	margin-right: 20px;
}
.pic-download-1 {
	background-image: url('../../../../../assets/images/assistant/pic-download-1.png');
}
.pic-download-2 {
	background-image: url('../../../../../assets/images/assistant/pic-download-2.png?v=1');
}
.steps-cont-tips {
	margin-bottom: 24px;
	padding: 16px 24px;
	display: inline-block;
	background-color: #F5F5F5;
	border-radius: 8px;
	font-size: 16px;
}
.i-tips {
	margin-right: 10px;
	font-size: 20px;
	color: #FE8135;
}
.important {
	color: #FE8135;
}
.bold {
	font-weight: bold;
}
.steps-cont-item-pic {
	margin-right: 20px;
}
.pic-install {
	margin-bottom: 8px;
	width: 440px;
	height: 247px;
}
.pic-install-1 {
	background-image: url('../../../../../assets/images/assistant/pic-install-1.png');
}
.pic-install-2 {
	background-image: url('../../../../../assets/images/assistant/pic-install-2.png?v=1');
}
.steps-cont-item-pic-text {
	position: relative;
	padding-left: 28px;
	display: flex;
	align-items: center;
}
.steps-cont-item-pic-text:before {
	content: ' ';
	position: absolute;
	left: 8px;
	top: 0;
	border: 8px solid transparent;
	border-bottom-color: #ccc;
}
.steps-cont-fail-tips {
	display: block;
	margin: 48px 0;
	padding: 16px 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	color: #2395FF;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}
.steps-cont-fail-tips:hover {
	color: #2395FF;
}