.industry-wrap {
  position: relative;
  width: 600px;
  background-color: #FAFAFA;
  border-radius: 8px;
  padding-left: 10px;
  cursor: pointer;
  .btn-set {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    .btn-click {
      font-size: 20px;
      color: #E2A45A;
    }
  }
}
.industry-pannel-wrap {
  /* display: none; */
  /* position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  z-index: 2; */
  /* height: 400px;
  overflow-y: auto; */
  /* background-color: #fff; */
  /* border-radius: 16px; */
  &.is-show {
    display: block;
  }
}
.industry-pannel {
  display: flex;
}
.parent-list,
.child-list {
  width: 40%;
  height: 400px;
  overflow-y: auto;
  padding-top: 10px;
}
.parent-item {
  position: relative;
  padding: 10px 16px;
  cursor: pointer;
  &.is-selected {
    color: #E2A45A;
  }
  .count {
    position: absolute;
    right: 20px;
    top: 0;
    padding: 0 8px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f00;
    color: #fff;
  }
}
.child-list {
  flex: 1;
}
.child-item {
  padding: 10px 16px;
  cursor: pointer;
  &.is-selected {
    color: #E2A45A;
  }
}
.child-checkall {
  padding-left: 16px;
}
.pannel-btns {
  padding-top: 20px;
  /* padding: 10px 16px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.point-industry-item {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
}
.point-industry-label {
  margin-right: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  background-color: rgba(226, 164, 90, 0.1);
  border-radius: 16px;
  text-align: center;
  color: #E2A45A;
}
.point-industry-values {
  margin-left: -16px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.point-industry-value {
  margin-bottom: 8px;
  margin-left: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #999;
  border-radius: 12px;
  text-align: center;
  color: #666;
}