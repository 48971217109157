.preview-cont {
	font-size: 16px;
  padding-bottom: 50px;
}
.preview-tit {
  margin-bottom: 16px;
}
.preview-list {
	display: flex;
}
.preview-item {
  width: 560px;
  &:not(:last-child) {
    margin-right: 24px;
  }
}
.preview-item:last-child {
  width: 432px;
}
.preview-item:last-child.preview-item-top {
	height: 181px;
}
.preview-item-image {
  height: 234px;
}
.preview-item-top {
  margin-bottom: 16px;
  position: relative;
  height: 234px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.preview-item-label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 4px 0px 4px 0px;
  font-size: 12px;
  color: #fff;
}
.preview-item-image {
  width: 100%;
  height: 100%;
}
.preview-item-text {
  text-align: center;
  color: #999;
}