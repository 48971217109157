.modal-cont {
  min-height: 600px;
  padding: 24px 150px;
  line-height: 1;
}
.tab-list {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tab-item {
    position: relative;
    width: 176px;
    height: 44px;
    border: 1px solid #ccc;
    border-radius: 22px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 24px;
    }
    &.is-selected {
      background-color: #E2A45A;
      border-color: #E2A45A;
      color: #fff;
      &::before,
      &::after {
        position: absolute;
        left: 50%;
        bottom: -34px;
        content: ' ';
        transform: translateX(-50%);
        border: 15px solid transparent;
        border-bottom-color: #F5F5F5;
        width: 0;
        height: 0;
        z-index: 2;
      }
      &::after {
        position: absolute;
        left: 50%;
        bottom: -33px;
        z-index: 1;
        border-bottom-color: #EEC98F;
      }
    }
  }
}
.tab-cont {
  position: relative;
  padding: 24px 150px;
  min-height: 350px;
  background-color: #F5F5F5;
  border-radius: 16px;
  border: 1px solid #EEC98F;
  .service-item {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    .service-item-tit {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .service-item-tit-name {
      font-weight: bold;
    }
    .service-item-tit-tips {
      font-weight: normal;
      color: #999;
    }
    .service-des-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .service-des-item-icon {
        color: #ccc;
        &.is-checked {
          color: #14C9C9;
        }
      }
    }
  }
}
.modal-footer {
  margin: -24px;
  border-top: 1px solid #E6E6E6;
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;
  .btn-confirm {
    padding: 0 24px;
    height: 40px;
    background-color: #E2A45A;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}