.order {
  background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
}
h3.title {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
  font-weight: bold;
}

p.all {
  color: #999;
  font-size: 14px;
  cursor: pointer;
}

.content {
  height: 170px;
}

.order ul {
  width: 100%;
}

.order ul li {
  position: relative;
}

.item {
  cursor: pointer;
}

span.count {
  position: absolute;
  top: -6px;
  right: -10px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #ff0032;
  color: #fff;
  font-size: 12px;
}