.filter-panel {
  margin-bottom: 16px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  font-size: 14px;
}

.filter-type {
  position: relative;
  display: flex;
  align-items: center;
}
.filter-label {
  width: 88px;
  text-align: right;
}
.filter-value {
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 18px 0;
}