.tips {
	padding-bottom: 40px;
	font-size: 16px;
	text-align: center;
}
.tips .i-tips {
	margin-bottom: 12px;
	font-size: 32px;
	color: #FF5E19;
}
.important {
	font-weight: bold;
}
.steps-cont-fail-tips {
	display: block;
	margin: 48px 0;
	padding: 16px 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	color: #2395FF;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}
.steps-cont-fail-tips:hover {
	color: #2395FF;
}