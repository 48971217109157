.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.flex {
	display: flex;
	align-items: center;
}
.ad-tit-label {
	font-size: 16px;
	font-weight: bold;
}

.ad-tit-tips {
	margin-left: 16px;
	font-size: 14px;
	color: #999;
}
.btn-manage {
	margin-left: 16px;
	padding: 0 8px;
	height: 24px;
	line-height: 24px;
	border-radius: 4px;
	border: 1px solid #EEC98F;
	color: #E2A45A;
}
.important {
	font-weight: bold;
	color: #333;
}
.btn-select-points {
	position: absolute;
	right: 24px;
	top: 16px;
	cursor: pointer;
	width: 72px;
  height: 28px;
  background-image: url(../../../../../assets/images/ad-exec/btn-select-points.png);
}

.no-record {
	margin-top: 20px;
	min-height: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F5F5F5;
	border-radius: 8px;
	color: #666;
}
.table-head {
	margin-top: 16px;
	margin-bottom: 8px;
	padding: 0 8px;
	height: 36px;
	background-color: #F5F5F5;
	border-radius: 4px;
	display: flex;
	align-items: center;
}
.table-head .table-td {
	padding-left: 16px;
	color: #666;
	flex: 0.8;
	display: flex;
	align-items: center;
}
.table-head .table-td:not(:last-child) {
	border-right: 1px solid #DBDBDB;
}
.table-head .table-td:nth-child(1) {
	flex: 0 432px;
}
.table-head .table-td:nth-child(2) {
	flex: 2;
}
.table-head .table-td:nth-child(4) {
	flex: 1;
}

.entity-list {
	margin-bottom: 16px;
}
.entity-item {
	margin-bottom: 8px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
}
.entity-info-wrap {
	position: relative;
	width: 440px;
	padding: 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	border-right: 1px solid #E6E6E6;
	display: flex;
	font-size: 12px;
	color: #999;
	line-height: 1.5;
}
.entity-image-cont {
	margin-right: 16px;
	position: relative;
	width: 88px;
	height: 88px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.3);
}
.entity-thumbnail {
	width: 88px;
	height: 88px;
	border-radius: 8px;
}
.entity-label {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 0 6px;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 0 8px 0 8px;
	height: 20px;
	line-height: 20px;
	color: #fff;
	text-align: center;
}
.entity-info {
	
}
.entity-tit {
	margin-bottom: 8px;
	font-weight: bold;
	line-height: 1.2;
	font-size: 16px;
	color: #333;
}
.entity-auto-check {
	position: absolute;
	right: -1px;
	top: -1px;
	height: 20px;
	line-height: 20px;
	background-color: #FFF5E8;
	border-radius: 0px 8px 0px 8px;
	border: 1px solid #FEB77C;
	padding: 0 8px;
	color: #FE8135;
	font-size: 12px;
}
.entity-dec {
	margin-bottom: 8px;
	max-width: 280px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.entity-point-info {
	margin-bottom: 8px;
}
.count-num {
	margin: 0 2px;
	font-weight: bold;
	color: #333;
}
.entity-prirce-info {
	
}
.entity-prirce-text {
	color: #FE8135;
}
.entity-prirce-int {
	font-size: 18px;
}
.btn-reselect {
	margin-top: 6px;
	display: inline-block;
	padding: 0 8px;
	height: 24px;
	line-height: 22px;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	cursor: pointer;
}

.point-list {
	flex: 1;
}
.point-item {
	position: relative;
	margin: 24px 0;
	display: flex;
	align-items: center;
}
.point-item.is-disabled {
	opacity: 0.3;
}
.point-item .table-td {
	padding-left: 16px;
	color: #666;
	flex: 0.8;
	display: flex;
	align-items: center;
}
.point-item .table-td:nth-child(1) {
	flex: 2;
}
.point-item .table-td:nth-child(3) {
	flex: 1;
}
.special-number {
	color: #FE8135 !important;
}
.table-td.table-link {
	font-size: 14px;
	color: #E2A45A;
	cursor: pointer;
}

.text-price-unit {
	font-size: 14px;
}
.text-price {
	font-size: 16px;
}
.rate-list {
  display: flex;
  align-items: center;
}
.rate-item {
  font-size: 16px;
  color: #FE8135;
}

.point-refuse {
	position: absolute;
	top: -10px;
	left: 60px;
	width: 60px;
  height: 35px;
  background-image: url('../../../../../assets/images/remo/icon-refuse.png?v=2');
}