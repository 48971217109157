.wallet-page {

}

.wallet-page-tit {
  margin: 16px 0;
  font-size: 12px;
  font-weight: bold;
}

.wallet-page-cont {
	padding: 20px;
	background-color: #fff;
	border-radius: 4px;
}

.wallet-page-top {
	padding: 40px;
	display: flex;
	align-items: center;
}
.wallet-list {
	margin-right: 150px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
}
.wallet-item {
  position: relative;
  margin: 0 60px;
  flex: 1;
}
.wallet-item:last-child,
.wallet-item:nth-child(2) {
  padding-left: 40px;
}
.wallet-item:nth-child(2):before {
  position: absolute;
  top: 50%;
  left: 0;
  content: '=';
  color: #333;
  font-size: 20px;
  transform: translateY(-50%);
}
.wallet-item:last-child:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: '+';
  color: #333;
  font-size: 20px;
  transform: translateY(-50%);
}
.wallet-item-tit {
  margin-bottom: 12px;
}
.wallet-unit {
	font-size: 12px;
}
.wallet-item-amount {
	font-weight: bold;
  font-size: 28px;
}
.btn-recharge {
  width: 80px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #E2A45A;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.wallet-date {
	margin: 40px 0;
}

.wallet-data {
	margin-bottom: 24px;
}
.wallet-data-tit {
	margin-bottom: 24px;
	padding: 20px;
	font-size: 20px;
	font-weight: bold;
	border-bottom: 1px solid #EDEDED;
}
.wallet-table {
	margin-bottom: 24px;
}
.wallet-money {
	font-weight: bold;
}
.is-positive {
	color: #FE8135;
}
.important {
	color: #E2A45A;
}

.order-form {
	margin-top: -10px;
	font-size: 16px;
}
.order-form-item {
	margin: 12px 0;
	display: flex;
	align-items: center;
}
.order-form-label {
	min-width: 100px;
	color: #999;
}

.other-tit {
	margin-top: 24px;
	padding-top: 12px;
	font-size: 16px;
	font-weight: bold;
	border-top: 1px dashed #DBDBDB;
}
.record-item {
	margin: 12px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.record-item-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.record-item-top-r {
	display: flex;
	align-items: center;
}
.record-reason {
	color: #f00;
}
.record-status {
	margin-right: 10px;
	color: #E2A45A;
}
.record-date {
	margin-top: 2px;
	color: #999;
	text-align: right;
}