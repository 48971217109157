/* 添加视频 */
.upload-modal-tit {
  padding-left: 24px;
  height: 48px;
  line-height: 48px;
  background-color: #F5F5F5;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: bold;
}
.upload-modal-content {
  padding: 24px 24px 0;
}
.upload-modal-t {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.upload-modal-t-r {
  flex: 1;
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 16px 16px 0 16px;
}
.preview-tit {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}
.preview-list {
  width: 162px;
  margin: 0 auto;
}
.preview-item {
  text-align: center;
}
.preview-item:not(:last-child) {
  margin-bottom: 20px;
}
.preview-item-top {
  margin-bottom: 8px;
  position: relative;
  height: 67px;
  background-color: #000;
  border: 1px solid #ccc;
  overflow: hidden;
}
.preview-item:last-child .preview-item-top {
  height: 87px;
}
.preview-item-label {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 8px;
  color: #fff;
  height: 24px;
  line-height: 24px;
  border-radius: 0px 0px 8px 0px;
}
.preview-item-image {
  width: 160px;
  height: 65px;
  object-fit: contain;
}
.preview-item:last-child .preview-item-image {
  height: 85px;
}
.upload-modal-t-l {
  margin-right: 24px;
  width: 780px;
  height: 326px;
  background-color: #F5F5F5;
  border-radius: 8px;
  font-size: 16px;
  color: #999;
  position: relative;
}
.upload-cont-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-modal-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #999;
}
.upload-icon {
  margin-bottom: 10px;
  width: 22px;
  height: 22px;
  background: center center no-repeat;
  background-image: url(../../../../assets/images/content/icon-plus.png);
  background-size: 100%;
}
.upload-error-tips {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: rgba(255,77,79,0.1);
  border-radius: 0 4px 4px 0;
  padding-left: 24px;
  color: #333;
}
.upload-error-tips-icon {
  margin-right: 9px;
  font-size: 18px;
  color: #FE8135;
}

.upload-error-tips.is-error,
.upload-error-tips-icon.is-error {
  color: #FF4D4F;
}
.upload-modal-video {
  width: 100%;
  height: 326px;
  background-color: #000;
  border-radius: 4px;
}
.upload-modal-edit {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 0 16px;
  height: 36px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 18px;
  color: #F5F5F5;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.upload-modal-edit .imediafont {
  margin-right: 6px;
  font-size: 16px;
}
.upload-modal-tips {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  line-height: 1.8;
}
.upload-modal-tips-tit {
  font-weight: bold;
  color: #333;
}
.upload-modal-link {
  margin-left: 12px;
  color: #2395FF;
  cursor: pointer;
}
.upload-modal-tips-item {
  margin-right: 10px;
}
.upload-modal-tips-label {
  font-weight: bold;
}

.upload-ing-mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
.upload-ing-cont {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imediafont-video {
  margin-bottom: 16px;
  font-size: 32px;
  color: #F5F5F5;
}
.upload-ing-tips {
  font-size: 16px;
  color: #F5F5F5;
}
.upload-progress {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
}

.upload-modal-form-tit {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}
.upload-modal-form-tit:before {
  content: '*';
  color: #FF3838;
}
.upload-modal-form-label {
  margin-left: 2px;
  font-weight: normal;
  color: #999;
  font-size: 14px;
}
.upload-modal-form-input {
  margin-bottom: 48px;
  width: 400px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  color: #333;
}
.upload-modal-footer {
  border-top: 1px solid #F5F5F5;
  padding: 12px 24px 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-confirm {
  background-color: #fff !important;
  border-color: #E2A45A !important; 
  border-radius: 4px;
  color: #E2A45A !important;
  text-shadow: none;
  border-radius: 4px;
}
.btn-confirm:hover {
  opacity: 0.8;
}
.btn-confirm[disabled],
.btn-confirm[disabled]:hover {
  border-color: #E6E6E6 !important; 
  background-color: #F5F5F5 !important;
  color: #ccc !important;
}
.btn-confirm:not(:last-child) {
  margin-right: 20px;
}