.crumb-list {
  margin: 16px 0;
  color: #999;
  font-size: 12px;
}
.crumb-parent {
  cursor: pointer;
}
.crumb-cur {
  color: #333;
  font-weight: bold;
}

.page-box {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
  .box-tit {
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid #EDEDED;
    font-weight: bold;
    font-size: 20px;
  }
  .box-cont {
    padding: 40px 24px;
  }
  .avatar {
    position: relative;
    width: 112px;
    height: 112px;
    background-color: #ECECEC;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .icon-avatar {
    font-size: 60px;
    color: #A1A6AD;
  }
  .btn-edit {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px 0px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-edit {
    font-size: 14px;
    color: #fff;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
  }
  .form-item {
    margin-bottom: 32px;
    width: 400px;
    display: flex;
  }
  .btn-link {
    color: #E2A45A;
    cursor: pointer;
  }
  .ml {
    margin-left: 12px;
  }
}