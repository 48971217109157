.ad-filter {
	margin-bottom: 24px;
}
.ad-filter-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.btn-toggle {
	display: flex;
	align-items: center;
	padding: 0 16px;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
	border: 1px solid #E6E6E6;
	cursor: pointer;
}
.icon-toggle {
	margin-left: 10px;
	color: #ccc;
	transform: rotate(180deg);
	&.bot {
		transform: rotate(0);
	}
}

.form {
	height: auto;
	overflow: hidden;
	&.hide {
		height: 0;
	}
}
.form-item {
	padding: 20px 0;
	display: flex;
	border-bottom: 1px solid #EDEDED;
}
.form-label {
	margin-right: 36px;
	width: 200px;
}
.form-value {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}
.text-location {
	margin-right: 50px;
	display: flex;
	align-items: center;
	color: #E2A45A;
	cursor: pointer;
}
.icon-location {
	margin-right: 10px;
}
.text-other {
	cursor: pointer;
}
.icon-other {
	margin-left: 8px;
	color: #ccc;
	transform: rotate(180deg);
	&.bot {
		transform: rotate(0);
	}
}

.distance-item {
	margin-right: 50px;
	cursor: pointer;
}
.is-selected {
	color: #E2A45A;
}

.brand-item {
	/* margin-right: 64px; */
	margin-bottom: 10px;
	width: 150px;
}
.btn-more {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}
.brand-wrap {
	overflow: hidden;
	height: auto;
	&.small {
		height: calc(32 * 3px);
	}
}

.radio-item {
	margin-right: 40px;
}