.point-cont {
  box-sizing: border-box;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 1;
  font-size: 14px;
}
.point-tabs {
  margin-bottom: 16px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
}
.point-tab {
  position: relative;
  padding: 12px 0;
  color: #666;
  cursor: pointer;
}
.point-tab.is-selected {
  font-weight: bold;
  color: #333;
}
.point-tab.is-selected:before {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: ' ';
  width: 24px;
  height: 3px;
  background-color: #E2A45A;
  transform: translateX(-50%);
}
.point-tab:not(:last-child) {
  margin-right: 40px;
}

.btn-table-link {
  color: #E2A45A;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}
.icon-dot {
  display: inline-block;
  margin-right: 4px;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
}
.icon-dot.warn {
  background-color: #ccc;
}
.icon-dot.success {
  background-color: #45C9B8;
}

.paging {
  margin-top: 20px;
}

.rate-list {
  display: flex;
  align-items: center;
}
.rate-item {
  font-size: 16px;
  color: #FE8135;
}