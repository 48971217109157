.stoke-page-tit {
	margin: 16px 0;
	color: #999;
	font-size: 12px;
}
.stoke-page-parent {
	cursor: pointer;
}
.stoke-page-cur {
	color: #333;
	font-weight: bold;
}

.stoke-page-cont {
	padding: 24px;
	background: #fff;
	border-radius: 8px;
}

.mpoint-tit {
  margin-bottom: 24px;
  padding-top: 4px;
  padding-bottom: 24px;
  border-bottom: 1px solid #EDEDED;
  font-size: 20px;
  font-weight: bold;
}
.modal-entity-name {
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid #E6E6E6;
  font-size: 14px;
}

.mpoint-top {
  justify-content: space-between;
}
.point-opts {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.point-opts-btn {
  background-color: #E2A45A;
  border-radius: 4px;
  border-color: #E2A45A;
}
.point-opts-btn:not(:last-child) {
  margin-right: 16px;
}
.point-opts-btn.default {
  background-color: #fff;
  color: #E2A45A;
}
.point-opts-btn:hover,
.point-opts-btn:focus {
  background-color: #E2A45A;
  border-color: #E2A45A;
  opacity: 0.8;
}
.point-opts-btn.default:hover,
.point-opts-btn.default:focus {
  background-color: #fff;
}
.point-opts-btn.default[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
.flex {
  display: flex;
  align-items: center;
}
.mpoint-top-l {
	margin-left: 64px;
}
.mpoint-top-r {
}
.mpoint-tips {
  font-size: 12px;
}
.mpoint-tips:not(:first-child) {
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid #E6E6E6;
}
.mpoint-tips-label {
  font-weight: bold;
}
.mpoint-tips-value {
  color: #666;
  font-size: 12px;
}
.mpoint-tips-value-item:not(:last-child) {
  margin-right: 16px;
}
.mpoint-tips-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.mpoint-tips-icon-2 {
  margin-left: 6px;
  color: #999;
  cursor: pointer;
}

.date-list {
	position: relative;
	margin-top: 24px;
}
.date-list::before {
	content: ' ';
	position: absolute;
	left: 30px;
	top: 0;
	bottom: 0;
	width: 2px;
	background-color: #E6E6E6;
}
.no-record {
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #999;
}
.no-record::before {
	display: none;
}
.date-item {
	position: relative;
	margin-bottom: 16px;
	display: flex;
	padding: 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	font-size: 14px;
}
.date-stop-label {
	position: absolute;
	right: 0;
	top: 0;
	color: #f00;
	font-weight: bold;
	background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  text-indent: -9999px;
  direction: ltr;
	width: 70px;
	height: 70px;
	background-image: url('../../../assets/images/point/icon-stop.png');
}
.date-item-label {
	z-index: 1;
	margin-right: 16px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: bold;
	font-size: 12px;
}
.date-item-label.before {
	width: 14px;
	height: 14px;
	background-color: #999;
	border: 2px solid #fff;
}
.date-item-label.today {
	margin-top: -3px;
	margin-left: -3px;
	width: 20px;
	height: 20px;
	background-color: #E2A45A;
	border: 1px solid #fff;
}
.date-item-label.future {
	width: 14px;
	height: 14px;
	background-color: #FFFFFF;
	border: 2px solid #ccc;
}
.date-item-date {
	margin-right: 16px;
	font-weight: bold;
}
.date-item-date.before {
	color: #999;
}
.date-item-result {
	width: 110px;
}
.sale-out-percent {
	font-weight: bold;
	color: #FE8135;
}

.date-item-data {
	flex: 1;
}
.date-item-cont {
	padding: 5px;
	background-color: #FFFFFF;
	border-radius: 4px;
	border: 2px solid #E6E6E6;
}
.degree-list {
	position: relative;
	font-size: 12px;
	font-weight: bold;
	color: #999;
	line-height: 1.2;
	display: flex;
	align-items: center;
}
.degree-list-start {
	position: absolute;
	left: 0;
}
.degree-list-end {
	position: absolute;
	right: 0;
}
.degree-item {
	flex: 1;
	text-align: center;
}

.minute-list {
	display: flex;
	align-items: center;
}
.minute-item {
	flex: 1;
	display: flex;
	align-items: center;
}
.minute-item:not(:last-child) {
	margin-right: 3px;
}
.step-item {
	flex: 1;
	height: 6px;
}
.step-item:not(:last-child) {
	border-right: 1px solid #fff;
}

.type-step-item {
	position: relative;
	height: 24px;
}
.type-text {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: #333;
}
.is-only {
	background-color: #FE8135;	
}
.is-remo {
	background-color: #FFE8E9;	
}
.is-self {
	background-color: #FFF8E8;	
}
.is-share {
	background-color: #45C9B8;	
}
.is-soled {
	background-color: #FF4D4F;
}
.is-in {
	background-color: #CCCCCC;
}