.home {
  height: 100%;
}

.home-content {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.img {
  
}

.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}