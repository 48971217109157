.modal-top {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #EDEDED;
  line-height: 1;
}
.point-name {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.point-name-text {
  margin-right: 18px;
  font-size: 20px;
  font-weight: bold;
}
.icon-tips {
  margin-left: 8px;
  color: #ccc;
  cursor: pointer;
}
.entity-name {
  color: #666;
}
.rate-list {
  display: flex;
  align-items: center;
}
.rate-item {
  font-size: 16px;
  color: #FE8135;
}

.ad-form {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
}
.ad-form-item {
  display: flex;
  align-items: center;
}
.ad-form-item:not(:last-child) {
  margin-right: 60px;
}
.ad-form-label {
  display: flex;
  align-items: center;
  color: #999;
}
.ad-form-icon {
  margin-right: 10px;
}
.text-days {
  font-size: 12px;
  color: #999;
}
.ad-form-value {
  display: flex;
  align-items: center;
}
.icon-crown {
  margin: 0 3px 0 5px;
  color: #fcae50;
}

.calendar-tips {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.between {
    justify-content: space-between;
  }
}
.calendar-tips-item {
  display: flex;
  align-items: center;
  color: #666;
  &:not(:last-child) {
    margin-right: 40px;
  }
  .icon-delivery {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    background-color: #FFF8E8;
    border: 1px solid #E6E6E6;
  }
  .text-important {
    color: #E2A45A;
    cursor: pointer;
  }
  .btn-more {
    margin-left: 8px;
    color: #999;
    font-size: 12px;
    cursor: pointer;
  }
}

.calendar-cell {
  margin: 0 2px;
  height: 80px;
  border-top: 1px solid #E6E6E6;
  &.is-today {
    border-top-color: #E2A45A;
  }
}
.calendar-delivery-bg {
  background-color: #FFF8E8;
  text-align: center;
  font-size: 16px;
  .price-unit {
    font-size: 12px;
  }
}
.calendar-date {
  padding: 7px 14px 0 0;
  text-align: right;
  font-weight: normal;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid #E6E6E6;
  .modal-footer-label {
    font-size: 14px;
  }
}