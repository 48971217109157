.entity-cont {
	width: 1232px;
	margin: 32px auto;
}
.entity-list {
	margin-bottom: 20px;
	min-height: 400px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.entity-item {
	margin: 0 28px 26px 0;
	padding: 32px 24px;
	display: flex;
	width: 602px;
	height: 176px;
	background-color: #fff;
	box-shadow: 0px 4px 24px 0px rgba(20, 19, 19, 0.1);
	border-radius: 8px;
}
.entity-item:nth-child(2n) {
	margin-right: 0;
}
.entity-item:hover {
 	box-shadow: 0px 4px 24px 0px rgba(20, 19, 19, 0.24);
}
.entity-thumb {
	margin-right: 14px;
	width: 112px;
	height: 112px;
	background-color: #ccc;
	border-radius: 8px;
}
.entity-info {
	position: relative;
	flex: 1;
}
.entity-name {
	margin-bottom: 15px;
	font-size: 18px;
	font-weight: bold;
	color: #8A573A;
}
.entity-location {
	margin-bottom: 30px;
	font-size: 14px;
	color: #666;
}
.count-number {
	font-weight: bold;
	font-size: 18px;
}

.entity-opts {
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: pointer;
}
.entity-opts:hover .btn {
	display: block;
}
.entity-opts:hover .entity-arrow {
	display: none;
}
.entity-arrow {
	overflow: hidden;
	width: 48px;
	height: 48px;
	background-color: #F5F5F5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.icon-arrow-r {
	font-size: 24px;
	color: #1F2731;
}
.btn {
	display: none;
	width: 120px;
	height: 40px;
	line-height: 40px;
	background-color: #465870;
	box-shadow: 0px 4px 8px 0px rgba(87, 88, 108, 0.3);
	border-radius: 8px;
	font-size: 16px;
	color: #fff;
	text-align: center;
	transition: all 300ms;
}