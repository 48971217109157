/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'imediafont';  /* Project id 3998378 */
  src: url('//at.alicdn.com/t/c/font_3998378_5shk2g6bhm9.woff2?t=1726304382595') format('woff2'),
       url('//at.alicdn.com/t/c/font_3998378_5shk2g6bhm9.woff?t=1726304382595') format('woff'),
       url('//at.alicdn.com/t/c/font_3998378_5shk2g6bhm9.ttf?t=1726304382595') format('truetype');
}

.imediafont {
  font-family: "imediafont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-plus:before {
  content: "\e65e";
}

.i-reload:before {
  content: "\e65f";
}

.i-check-solid:before {
  content: "\e679";
}

.i-click:before {
  content: "\e62d";
}

.i-success:before {
  content: "\e60e";
}

.i-delete:before {
  content: "\e67e";
}

.i-checked:before {
  content: "\e882";
}

.i-question-media:before {
  content: "\e602";
}

.i-more:before {
  content: "\e69e";
}

.i-crown:before {
  content: "\e692";
}

.i-check:before {
  content: "\e6a0";
}

.i-pencile-simple:before {
  content: "\e6e4";
}

.i-plus-circle:before {
  content: "\e600";
}

.i-play:before {
  content: "\e680";
}

.i-upload:before {
  content: "\e61d";
}

.i-image:before {
  content: "\e69c";
}

.i-arrow-right:before {
  content: "\e636";
}

.i-search:before {
  content: "\e604";
}

.i-fire:before {
  content: "\e669";
}

.i-cinema:before {
  content: "\e695";
}

.i-minicode-0:before {
  content: "\e63e";
}

.i-building:before {
  content: "\e690";
}

.i-user-media:before {
  content: "\e696";
}

.i-trangle-b:before {
  content: "\e69f";
}

.i-tips2:before {
  content: "\e6b2";
}
