.token {
	background-color: #fff;
  border-radius: 8px;
  line-height: 1.2;
  cursor: pointer;
}
h3.title {
  padding: 24px 0 16px 24px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
  font-weight: bold;
}
.token-icon-toggle {
	margin-left: 8px;
	font-size: 16px;
	color: #ccc;
	cursor: pointer;
}
.token-cont {
	height: 170px;
	display: flex;
	align-items: center;
}
.token-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/*margin: 0 90px;*/
}
.token-tit {
	margin-bottom: 8px;
	font-size: 16px;
	font-weight: bold;
}
.token-tit-unit {
	font-size: 14px;
	color: #999;
}
.token-price {
	font-size: 28px;
	font-weight: bold;
}