.intro-page {
  line-height: 1;
}
.intro-cont {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #FFF8E8;
  border: 1px solid #EEC98F;
  border-radius: 8px;
  &:nth-child(1) {
    margin-bottom: 0;
    border-color: #EDEDED;
    background-color: #F5F5F5;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.intro-tit {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
  .intro-tit-tips {
    margin-left: 8px;
    font-weight: normal;
    font-size: 12px;
    color: #999;
  }
}
.intro-tips {
  color: #666;
  line-height: 1.5;
}
.intro-p {
  margin-bottom: 8px;
}

.price-box {
  display: flex;
  border: 1px solid #EDEDED;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.price-box-l {
  flex: 1;
}
.price-box-r {
  border-left: 1px solid #EDEDED;
  max-height: 650px;
  overflow: auto;
}
.intro-box {
  padding: 24px 24px 0;
  &:nth-child(2) {
    border-top: 1px solid #EDEDED;
  }
}


.intro-table {
  margin-top: 16px;
  .intro-th {
    display: flex;
    background-color: #F5F5F5;
    .intro-td {
      padding-top: 12px;
      border-left: 1px solid #EDEDED;
      height: 36px;
    }
  }
  .intro-tr {
    position: relative;
    display: flex;
    align-items: center;
    color: #666;
  }
  .intro-td {
    flex: 0.5;
    padding: 22px 0 12px 32px;
    line-height: 1.2;
    white-space: nowrap;
  }

  .rate-list {
    display: flex;
    align-items: center;
  }
  .rate-item {
    font-size: 16px;
    color: #FE8135;
  }
}

.has-border {
  border-top: 1px dashed #E6E6E6;
}

.intro-form-item {
  margin: 28px 0;
  display: flex;
  align-items: center;
  .icon-crown {
    margin: 0 2px;
    color: #fcae50;
  }
  .intro-form-label {
    width: 240px;
    margin-left: 24px;
  }
  .intro-form-value {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .intro-form-value-tips {
    font-size: 12px;
    color: #999999;
  }
}
