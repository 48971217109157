.title {
	margin: -24px -24px 30px;
	padding-left: 24px;
	height: 48px;
	line-height: 48px;
	background-color: #F5F5F5;
	border-radius: 8px 8px 0px 0px;
	font-size: 16px;
	color: #333;
	font-weight: bold;
}

.icon-close {
	position: relative;
	top: -3px;
}

.content {
	padding: 0 24px;
}
.content-title {
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 20px;
}
.content-tit-tips {
	margin-left: 8px;
	font-weight: normal;
	font-size: 14px;
	color: #FF2828;
}
.content-subtit {
	margin-bottom: 16px;
	font-size: 18px;
	font-weight: bold;
}

.content-pa {
	margin-bottom: 5px;
	font-size: 16px;
	color: #666;
}

.footer {
	padding: 16px 24px;
	margin: 0 -24px -24px;
	border-top: 1px solid #E6E6E6;
	background-color: #F5F5F5;
	display: flex;
	justify-content: flex-end;
}
.btn {
	margin-left: 20px;
	padding: 0 24px;
	height: 36px;
	line-height: 36px;
	background-color: #465870;
	border: none;
	box-shadow: 0px 4px 8px 0px rgba(87, 88, 108, 0.3);
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}
.btn[disabled] {
	background-color: rgba(70, 88, 112, 0.5);
	box-shadow: none;
}
.btn:hover {
	opacity: 0.9;
}
.btn:active {
	opacity: 0.8;
}
.btn.btn-cancel {
	background-color: #f1f1f1;
	color: #666;
}