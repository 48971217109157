.cinema-issue {
  
}

h3.title {
  padding: 24px 0 16px 36px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
  font-weight: bold;
}

span.label {
  color: #999;
}