.point-cont {
  box-sizing: border-box;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 1;
  font-size: 14px;
}
.point-tabs {
  margin-bottom: 16px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
}
.point-tab {
  position: relative;
  padding: 12px 0;
  color: #666;
  cursor: pointer;
}
.point-tab.is-selected {
  font-weight: bold;
  color: #333;
}
.point-tab.is-selected:before {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: ' ';
  width: 24px;
  height: 3px;
  background-color: #E2A45A;
  transform: translateX(-50%);
}
.point-tab:not(:last-child) {
  margin-right: 40px;
}

.point-opts {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.btn-confirm {
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  background-color: #E2A45A;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  &.is-disabled {
    opacity: 0.3;
    cursor: default;
  }
}
.point-opts-btn {
  background-color: #E2A45A;
  border-radius: 4px;
  border-color: #E2A45A;
}
.point-opts-btn:not(:last-child) {
  margin-right: 16px;
}
.point-opts-btn.default {
  background-color: #fff;
  color: #E2A45A;
}
.point-opts-btn:hover,
.point-opts-btn:focus {
  background-color: #E2A45A;
  border-color: #E2A45A;
  opacity: 0.8;
}
.point-opts-btn.default:hover,
.point-opts-btn.default:focus {
  background-color: #fff;
}
.point-opts-btn.default[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
.btn-table-link {
  color: #E2A45A;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.flex {
  display: flex;
  align-items: center;
}
.icon-dot {
  display: inline-block;
  margin-right: 4px;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
}
.icon-dot.primary {
  background-color: #33A6FF;
}
.icon-dot.warn {
  background-color: #FE8135;
}
.icon-dot.success {
  background-color: #45C9B8;
}
.icon-dot.error {
  background-color: #FF4D4F;
}

.paging {
  margin-top: 20px;
}

.rate-list {
  display: flex;
  align-items: center;
}
.rate-item {
  font-size: 16px;
  color: #FE8135;
}

.point-image-wrap {
  display: inline-block;
  width: 72px;
  height: 41px;
  border-radius: 4px;
  background: center center no-repeat;
  background-size: 100%;
  vertical-align: middle;
  &.CINEMA {
    background-image: url('../../../../assets/images/point/bg-point-cinema.png');
  }
  &.LIGHTBOX {
    background-image: url('../../../../assets/images/point/bg-point-lightbox.png');
  }
  &.HALL_NAME {
    background-image: url('../../../../assets/images/point/bg-point-hallname.png');
  }
  &.LCD {
    background-image: url('../../../../assets/images/point/bg-point-lcd.png');
  }
  &.LED {
    background-image: url('../../../../assets/images/point/bg-point-led.png');
  }
  .point-image {
    width: 72px;
    height: 41px;
    background-color: #000;
    border-radius: 4px;
    object-fit: contain;
  }
}