.sign-in {
  position: relative;
  height: inherit;
  background-color: #040219;
  overflow: hidden;
  color: #fff;
}

.sign-cont {
  width: 1232px;
  height: 488px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
}
.welcome-text {
  margin-bottom: 89px;
  width: 612px;
  height: 204px;
  background-image: url('../../../../assets/images/assistant/welcome-text.png?v=1');
}
.welcome-cont {
  width: 540px;
  padding: 24px 0 40px 47px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  color: #F5F5F5;
}
.welcome-subtit {
  margin-bottom: 40px;
  font-size: 24px;
}
.feature-list {
  font-size: 18px;
  color: #ccc;
}
.feature-item {
  margin-bottom: 22px;
}
.feature-item:last-child {
  margin-bottom: 0;
}

.sign {
  width: 462px;
  height: 488px;
  border-radius: 8px;
  background-color: #fff;
}

.switch {
  margin-bottom: 48px;
  border-bottom: 1px solid #E6E6E6;
  cursor: pointer;
}

.tab {
  position: relative;
  margin-right: 64px;
  padding-bottom: 16px;
  font-size: 24px;
  color: #333;
}

.tab.active {
  color: #333;
}
.tab.active:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: ' ';
  width: 48px;
  height: 4px;
  background-color: #465870;
  transform: translateX(-50%);
}

.btn-registe {
  margin-top: -40px;
  padding: 24px 0;
  font-size: 16px;
  color: #666;
  text-align: center;
  cursor: pointer;
}

.footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  color: #DFDFDF;
}
.footer a {
  color: #DFDFDF;
}