/* 添加视频 */
.upload-modal-tit {
  padding-left: 24px;
  height: 48px;
  line-height: 48px;
  background-color: #F5F5F5;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: bold;
}
.upload-modal-content {
  padding: 24px 24px 0;
}
.upload-modal-tips {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  line-height: 1.8;
}
.upload-modal-tips-tit {
  font-weight: bold;
  color: #333;
}
.upload-modal-tips-item {
  margin-right: 10px;
}
.upload-modal-tips-label {
  font-weight: bold;
}
.upload-modal-form-tit {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}
.upload-modal-form-tit:before {
  content: '*';
  color: #FF3838;
}
.upload-modal-form-label {
  margin-left: 2px;
  font-weight: normal;
  color: #999;
  font-size: 14px;
}
.upload-modal-form-input {
  margin-bottom: 48px;
  width: 400px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  color: #333;
}
.upload-modal-footer {
  border-top: 1px solid #F5F5F5;
  padding: 12px 24px 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-confirm {
  background-color: #E2A45A !important;
  border-color: #E2A45A !important; 
  border-radius: 4px;
}
.btn-confirm:hover {
  opacity: 0.7;
}
.btn-confirm[disabled],
.btn-confirm[disabled]:hover {
  color: #fff;
  border-color: #E6E6E6 !important; 
  background-color: #F5F5F5 !important;
  color: #ccc;
}
.btn-confirm:not(:last-child) {
  margin-right: 20px;
}

/*合成视频*/
.upload-modal-spin {
	color: #999;
}
.duration-modal-tit {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
}
.duration-list {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.duration-item {
  margin-right: 16px;
  width: 110px;
  height: 40px;
  line-height: 40px;
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.duration-item.is-selected {
  background-color: #fff;
  border-color: #E2A45A;
  color: #E2A45A;
}
.btn-cancel {
  margin-right: 16px;
  border-radius: 4px;
}
.image-cont {
  margin-top: 40px;
  margin-bottom: 16px;
  position: relative;
  padding-top: 33rpx;
}
.count {
  margin: 0 2px;
  font-weight: bold;
}
.f-black {
  color: #333;
}
.image-count {
  position: absolute;
  right: 0;
  top: -33px;
  padding: 0 16px 0 24px;
  height: 33px;
  line-height: 32px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  border-bottom: none;
  border-radius: 32px 8px 0 0;
  font-size: 12px;
}
.image-count-text {
  margin: 0 4px;
  font-weight: bold;
}
.image-list-wrap {
  display: flex;
  align-items: center;
  background: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
}
.image-btn {
  width: 126px;
  height: 126px;
  background: #FFFFFF;
  border-radius: 8px;
  border-right: 1px solid #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #999;
  cursor: pointer;
}
.image-btn-icon {
  margin: 0 auto 5px;
  width: 22px;
  height: 22px;
  background: center center no-repeat;
  background-image: url(../../../../assets/images/content/icon-plus.png);
  background-size: 100%;
}
.image-btn-text {
	font-size: 12px;
	color: #999;
}
.image-list-inner {
  overflow-x: auto;
  flex: 1;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.image-list-inner::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}
/*定义滚动条轨道 内阴影+圆角*/
.image-list-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #fdfdfd;
}
/*定义滑块 内阴影+圆角*/
.image-list-inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ddd;
}
.image-list {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 126px;
}
.image-item {
  margin-right: 10px;
  position: relative;
  width: 224px;
  height: 94px;
  background-color: #F5F5F5;
  border-radius: 4px;
}
.image-index {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 4px;
  height: 20px;
  line-height: 20px;
  background: rgba(0,0,0,0.5);
  border-radius: 4px 0px 4px 0px;
  font-size: 12px;
  color: #fff;
}
.image-node {
	width: 224px;
	height: 94px;
  object-fit: contain;
  background-color: #ccc;
	border-radius: 4px;
}
.image-item:hover .image-opts {
	display: flex;
}
.image-opts {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  border-radius: 4px;
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.image-preview {
  margin-right: 14px;
  cursor: pointer;
  color: #fff;
}
.image-del {
  cursor: pointer;
}

.image-compose {
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 442px;
  background-color: #F5F5F5;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  cursor: pointer;
}
.image-compose-empty {
	font-size: 16px;
	color: #999;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.video-icon {
	margin-bottom: 8px;
	font-size: 48px;
	color: #999;
}
.image-compose-video {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-compose-carousel {
	width: 1056px;
	height: 442px;
	border-radius: 8px;
}
.image-compose-node {
	width: 1056px;
	height: 442px;
  object-fit: contain;
	border-radius: 8px;
}
.video-progress {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -4px;
	width: 100%;
}
.video-btn {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.7);
	border-radius: 8px;
	display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.video-btn-icon {
	margin-bottom: 10px;
	font-size: 60px;
	color: #fff;
	opacity: 0.8;
}
.video-durtaion {
	font-size: 14px;
	color: #fff;
}