
.ad-page-bottom {
	padding: 16px 24px;
	background-color: #fff;
	border-radius: 8px;
	height: 72px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px -2px 8px 1px rgba(0,0,0,0.15);
	border-radius: 8px 8px 0px 0px;
}
.ad-page-tips {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #999;
}
.ad-page-tips-label {
	color: #333;
}
.ad-page-bottom-l {
	display: flex;
	align-items: center;
}
.ad-page-bottom-label {
	margin-right: 8px;
	font-size: 16px;
	font-weight: bold;
}
.bottom .ad-page-bottom-label {
	font-weight: normal;
}
.icon-tips {
	margin-right: 8px;
	font-size: 14px;
	color: #ccc;
	cursor: pointer;
}
.text-price {
	font-size: 16px;
	color: #FE8135;
}
.text-price-int {
	font-size: 24px;
}
.ad-page-bottom-r {
	display: flex;
	align-items: center;
}
.btn-comfirm {
	margin-left: 16px;
	padding: 0 24px;
	line-height: 24px;
	height: 40px;
	border-radius: 4px;
	background-color: #E2A45A;
	border: 1px solid #E2A45A;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.btn-comfirm[disabled] {
	background-color: #F5F5F5;
	border-color: #E6E6E6;
	color: #ccc;
	cursor: default;
}
.big-margin {
	margin-left: 48px;
}