.steps-cont {
	padding: 0 40px;
}
.steps-cont-tips {
	margin-bottom: 24px;
	padding: 16px 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.i-tips {
	margin-right: 10px;
	font-size: 20px;
	color: #FE8135;
}
.steps-cont-tips-btn {
	color: #2395FF;
	cursor: pointer;
}
.steps-cont-btn {
	margin: 40px 0 24px;
	text-align: center;
}
.steps-cont-item-text {
	margin-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.steps-cont-item-pic {
	margin-right: 20px;
}
.pic-active {
	margin-bottom: 8px;
	width: 440px;
	height: 247px;
}
.pic-active-1 {
	background-image: url('../../../../../assets/images/assistant/pic-active-1.png');
}
.steps-cont-item-pic-text {
	position: relative;
	padding-left: 28px;
	display: flex;
	align-items: center;
}
.steps-cont-item-pic-text:before {
	content: ' ';
	position: absolute;
	left: 8px;
	top: 0;
	border: 8px solid transparent;
	border-bottom-color: #ccc;
}
.steps-cont-fail-tips {
	display: block;
	margin: 48px 0;
	padding: 16px 24px;
	background-color: #F5F5F5;
	border-radius: 8px;
	color: #2395FF;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}
.steps-cont-fail-tips:hover {
	color: #2395FF;
}