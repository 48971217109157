.share-entity-page {
	/* min-height: 100vh; */
	overflow-y: auto;
	.entity-page-tit {
		padding: 16px 0 10px;
		background-color: #fff;
		text-align: center;
		font-weight: bold;
	}
	.entity-search-wrap {
		padding: 8px 16px 0;
		background-color: #fff;
		.entity-search {
			margin-bottom: 8px;
		}
		.entity-filter-wrap {
			position: relative;
		}
		.entity-filter {
			display: flex;
			align-items: center;
			height: 44px;
		}
		.entity-filter-item {
			flex: 1;
			display: flex;
			align-items: center;
			.txt-selected {
				color: #E2A45A;
			}
		}
		.entity-arrow {
			margin-left: 4px;
			border: 5px solid transparent;
			border-top-color: #999;
			margin-top: 5px;
			transition: transform ease 0.3s;
			&.up {
				margin-top: 0;
				transform: rotate(180deg);
			}
			&.has-selected {
				border-top-color: #E2A45A;
			}
		}
	}
	.entity-filter-mask {
		position: fixed;
		z-index: 1;
		top: 160px;
		left: -16px;
		right: -16px;
		background-color: rgba(0, 0, 0, 0.3);
		bottom: 0;
	}
	.entity-filter-panel {
		position: fixed;
		z-index: 2;
		left: 0;
		right: 0;
		background-color: #fff;
		border-top: 1px solid #F5F5F5;
		.list {
			padding: 10px 16px;
			max-height: 200px;
			overflow-y: auto;
			.item {
				padding: 10px 0;
				&.is-selected {
					color: #E2A45A;
				}
			}
		}
		.filter-opts {
			padding: 10px;
			background-color: #f5f5f5;
			.btn {
				flex: 1;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				color: #E2A45A;
				border: 1px solid #E2A45A;
				&.btn-normal {
					margin-right: 16px;
					background-color: #fff;
				}
				&.btn-confirm {
					background-color: #E2A45A;
					color: #fff;
				}
			}
			
		}
	}

	.entity-list {
		padding: 12px 12px 0;
	}
	.entity-item {
		border: 1px solid #E5E5E5;
		border-radius: 8px;
		&:not(:last-child) {
			margin-bottom: 12px;
		}
		.entity-info {
			padding: 12px;
			display: flex;
		}
		.entity-image-cont {
			margin-right: 12px;
			position: relative;
			width: 56px;
			height: 56px;
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.3);
		}
		.entity-thumbnail {
			width: 56px;
			height: 56px;
			border-radius: 4px;
			object-fit: contain;
			background-color: #000;
		}
		.entity-chain {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 0 6px;
			background-color: rgba(0, 0, 0, 0.7);
			border-radius: 0 4px 0 4px;
			height: 16px;
			line-height: 16px;
			color: #FEC1A1;
			text-align: center;
			font-size: 10px;
		}
		.entity-name {
			margin-bottom: 8px;
			font-weight: bold;
		}
		.entity-addr {
			margin-bottom: 8px;
			font-size: 13px;
			color: #666;
		}
		.entity-labels {
			margin-bottom: 8px;
			display: flex;
			flex-wrap: wrap;
		}
		.entity-label {
			padding: 0 8px;
			height: 20px;
			line-height: 18px;
			border-radius: 10px;
			border: 1px solid #CCC;
			font-size: 12px;
			color: #666;
			&:not(:last-child) {
				margin-right: 6px;
			}
		}
		.entity-info-tips {
			font-size: 12px;
			color: #666;
		}
		.text-number {
			margin: 0 2px;
			font-weight: bold;
			font-size: 14px;
			color: #333;
		}
		.point-list {
			background-color: #fff;
			border-radius: 0 0 8px 8px;
			padding: 12px;
		}
		.point-item {
			display: flex;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
			.point-item-l {
				margin-right: 12px;
				position: relative;
				width: 88px;
				height: 66px;
				border-radius: 4px;
				.point-number {
					position: absolute;
					left: 0;
					top: 0;
					height: 14px;
					line-height: 14px;
					padding: 0 4px;
					background-color: rgba(0, 0, 0, 0.6);
					border-radius: 4px 0px 4px 0px;
					color: #fff;
					font-size: 12px;
				}
				.point-thumbnail {
					width: 88px;
					height: 66px;
					border-radius: 4px;
					object-fit: contain;
					background-color: #000;
				}
			}
		}
		.point-name {
			margin-bottom: 8px;
			font-weight: bold;
		}
		.point-labels {
			display: flex;
			flex-wrap: wrap;
		}
		.point-label {
			margin-right: 8px;
			padding: 0 8px;
			height: 18px;
			line-height: 16px;
			border-radius: 12px;
			background-color: #F5F5F5;
			font-size: 12px;
			color: #666;
		}
	}

	.no-record {
		background-color: #fff;
		min-height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #666;
	}
}