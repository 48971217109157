.thumbs {
  height: 204px;
  border-radius: 12px 12px 0 0;
  background-color: #1d1f23;
}

.carousel {
  width: calc(100% - 48px);
}

.carousel-item {
  
}

.carousel-item-content {
  height: 204px;
}

.card {
  position: relative;
  box-sizing: border-box;
  width: 192px;
  height: 140px;
  border-radius: 4px;
  background-color: #fff;
  transition: all 250ms linear;
}

.card.selected {
  width: 224px;
  height: 164px;
  border: 2px solid #E2A45A;
}

.card .number {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  color: #DBDBDB;
  z-index: 1;
}

.card .cover {
  position: relative;
  height: 108px;
  overflow: hidden;
}

.card .cover span.unusual {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 96px;
  transform: translate(-50%);
  border: 1px solid #E2A45A;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #E2A45A;
  opacity: .8;
}

.card ul.bottom {
  height: calc(100% - 108px);
  font-size: 12px;
}

.card ul.bottom li p {
  margin-top: -5px;
}

.switch {
  width: 48px;
  color: #999;
}

.up, .down {
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #484a4d;
  border-radius: 50%;
}