.ad-page {
	position: relative;
}

.ad-page-cont {
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #E2A45A;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #E2A45A;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.cashier-top {
	margin-bottom: 72px;
	text-align: center;
}
.cashier-tit {

}
.text-price {
	margin: 8px 0 24px;
	color: #FE8135;
}
.text-price-int {
	font-size: 24px;
}
.pay-tips {
	color: #999;
}
.pay-tips-important {
	color: #FF4D4F;
}

.paytype-item {
	margin-bottom: 16px;
	padding: 40px;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	line-height: 1.2;
}
.paytype-item.is-selected {
	box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.16);
	border: 1px solid #E2A45A;
}
.paytype-item-top {
	position: relative;
	display: flex;
	align-items: center;
}
.paytype-item-checkbox {
	margin-right: 16px;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	cursor: pointer;
}
.paytype-item.is-selected .paytype-item-checkbox {
	border: 4px solid #E2A45A;
}
.paytype-name {
	font-size: 16px;
	font-weight: bold;
}
.paytype-item-top .text-price {
	position: absolute;
	right: 0;
	top: -10px;
	font-size: 20px;
}
.paytype-item-top .text-price .text-price-unit {
	font-size: 16px;
}
.paytype-amount-tips {
	margin-top: 10px;
	margin-left: 32px;
	font-size: 16px;
}
.paytype-amount-tips .text-price {
	font-size: 16px;
}
.paytype-amount-important {
	margin: 0 24px;
	color: #FF4D4F;
}
.btn-recharge {
	display: inline-block;
	padding: 0 8px;
	height: 24px;
	line-height: 24px;
	border-radius: 4px;
	border: 1px solid #EEC98F;
	color: #BD7D38;
	cursor: pointer;
}
.paytype-form {
	margin-top: 40px;
	padding-top: 30px;
	padding-left: 30px;
	border-top: 1px solid #EDEDED;
}
.paytype-form-tit {
	color: #666;
}
.paytype-form-item {
	position: relative;
	margin: 12px 0 40px;
	display: flex;
	align-items: center;
}
.paytype-form-ipt {
	position: relative;
	z-index: 1;
	width: 288px;
	height: 48px;
	border: none;
	background: transparent;
}
.paytype-form-ipt-wrap {
	position: absolute;
	z-index: 0;
	left: 0;
	top: 0;
	width: 288px;
	height: 48px;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
	display: flex;
	align-items: center;
}
.paytype-form-ipt-item {
	width: 48px;
	height: 48px;
}
.paytype-form-ipt-item:not(:last-child) {
	border-right: 1px solid #E6E6E6;
}
.paytype-form-tips {
	margin-left: 24px;
	color: #999;
}
.paytype-form-tips.error {
	color: #f00;
}
.btn-pay {
	display: inline-block;
	padding: 0 24px;
	height: 40px;
	line-height: 40px;
	background-color: #E2A45A;
	border-radius: 4px;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.btn-pay.is-disabled {
	opacity: 0.5;
	cursor: default;
}
.weixin-cont {
	margin-left: 32px;
}
.weixin-tips {
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: bold;
}
.weixin-code-img {
	margin-top: -8px;
	margin-left: -32px;
	width: 320px;
	height: 320px;
	border-radius: 8px;
	/*border: 1px solid #E6E6E6;*/
}