.point-page {

}

.point-page-tit {
  margin: 16px 0;
  font-size: 12px;
  font-weight: bold;
}

.point-cont {
  position: relative;
  margin-bottom: 16px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  .point-cont-tit {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 20px;
  }
}
.point-status {
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 24px;
  font-weight: bold;
  &.warn {
    color: #FE8135;
  }
  &.success {
    color: #45C9B8;
  }
}
.btn-example {
  margin-top: -40px;
  text-align: right;
  color: #999;
  cursor: pointer;
}
.form-tips {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #999;
}
.img-list {
  display: flex;
  flex-wrap: wrap;
  .img-item-wrap {
    margin-bottom: 16px;
    width: 480px;
    height: 270px;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: center center no-repeat;
    background-size: 100%;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &.CINEMA {
      background-image: url('../../../assets/images/point/bg-point-cinema.png');
    }
    &.LIGHTBOX {
      background-image: url('../../../assets/images/point/bg-point-lightbox.png');
    }
    &.HALL_NAME {
      background-image: url('../../../assets/images/point/bg-point-hallname.png');
    }
    &.LCD {
      background-image: url('../../../assets/images/point/bg-point-lcd.png');
    }
    &.LED {
      background-image: url('../../../assets/images/point/bg-point-led.png');
    }
    &.default {
      cursor: default;
    }
  }
  .img-item {
    width: 480px;
    height: 270px;
    background-color: #000;
    border-radius: 8px;
    object-fit: contain;  
  }
}
.tab-list {
  margin-bottom: 24px;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
  .tab-item {
    position: relative;
    padding-bottom: 10px;
    line-height: 24px;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 40px;
    }
    &.is-selected {
      font-weight: bold;
      font-size: 20rpx;
      color: #333;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 24px;
        height: 3px;
        background-color: #E2A45A;
      }
    }
  }
}

.point-audit-btn {
  display: flex;
  justify-content: flex-end;
}
.audit-list {
  display: flex;
}
.audit-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 56px;
  padding-right: 24px;
  height: 36px;
  /* background-color: #FFFFFF; */
  border-radius: 18px;
  border: 1px solid #CCC;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 40px;
  }
  .audit-icon {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 36px;
    height: 36px;
    /* background-color: #fff; */
    border: 1px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.is-success {
    border-color: #45C9B8;
    /* background-color: #45C9B8; */
    color: #fff;
    .audit-icon {
      border-color: #fff;
      /* background-color: #45C9B8; */
    }
  }
  &.is-error {
    border-color: #FF4D4F;
    /* background-color: #FF4D4F; */
    color: #fff;
    .audit-icon {
      border-color: #fff;
      /* background-color: #FF4D4F; */
    }
  }
}
.audit-textarea {
  margin: 16px 0;
  width: 60%;
  min-height: 100px;
}
.is-show {
  height: auto;
}
.is-hide {
  height: 0;
  overflow: hidden;
}