.stock-form {
  padding-bottom: 40px;
  border-bottom: 24px solid #F5F5F5;
}
.stock-form-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.stock-form-label {
  color: #999;
}
.halt-list {
}
.halt-list-tit {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: bold;
}