.staff {
	background-color: #fff;
  border-radius: 8px;
  height: 176px;
	font-size: 16px;
}
.title {
  padding: 24px 0 16px 24px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 20px;
  font-weight: bold;
}

.content {
	padding: 32px 40px 0;
}
.content-num {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;
  color: #E2A45A;
}
.staff-cont {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}