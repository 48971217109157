.ad-page {
	position: relative;
}
.ad-page.is-paywait {
	padding-bottom: 88px;
}

.ad-page-cont {
	position: relative;
	margin-bottom: 16px;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
}
.ad-page-tit {
	margin: 0 -24px 24px;
	padding-left: 24px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #EDEDED;
}
.btn-back {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #E2A45A;
	cursor: pointer;
}
.icon-right {
	margin-right: 8px;
	transform: rotate(90deg);
	font-size: 13px;
	color: #E2A45A;
}
.btn-back-text {
	
}
.ad-page-tit-text {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 1px solid #ccc;
	font-weight: bold;
	font-size: 20px;
}

.ad-page-cont-inner {
	margin-bottom: 24px;
	position: relative;
	display: flex;
}

.ad-page-cont-tit {
	margin-bottom: 16px;
	font-size: 16px;
	font-weight: bold;
}

.ad-form-item {
	display: flex;
	align-items: center;
}
.ad-form-item {
	margin-right: 60px;
}
.ad-form-label {
	display: flex;
	align-items: center;
	color: #999;
}
.ad-form-icon {
	margin-right: 10px;
}
.text-days {
	font-size: 12px;
	color: #999;
}
.ad-form-value {
	display: flex;
	align-items: center;
}

/*列表*/
.content-table {
	margin: 24px 0;
}
.content-table td {
  vertical-align: middle;
}
.video-wrap {
  position: relative;
  width: 200px;
  height: 84px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
.video-result-tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
}
.video-image {
  width: 200px;
  height: 84px;
  background-color: #000;
  border-radius: 4px;
  object-fit: contain;
}
.video-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.8;
}
.video-mask:hover {
  opacity: 1;
}
.video-btn-icon {
	font-size: 20px;
}
.bold {
	font-weight: bold;
}

.icon-status {
	margin-right: 4px;
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	&.suc {
		background-color: #45C9B8;
	}
	&.warn {
		background-color: #FE8135;
	}
	&.gray {
		background-color: #bbb;
	}
	&.error {
		background-color: #FF4D4F;
	}
}
.btn-opt {
	color: #E2A45A;
	cursor: pointer;
}

.table-td-inner {
	display: flex;
	align-items: center;
}
