.point-top {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #EDEDED;
  line-height: 1.2;
  .point-name {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: bold;
  }
  .entity-name {
    color: #666;
    font-size: 14px;
  }
}

.ad-form {
  line-height: 1.2;
}
.ad-form-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.ad-form-item:not(:last-child) {
  margin-right: 60px;
}
.ad-form-label {
  display: flex;
  align-items: center;
  color: #999;
}
.ad-form-icon {
  margin-right: 10px;
}
.text-days {
  font-size: 12px;
  color: #999;
}
.ad-form-value {
  display: flex;
  align-items: center;
}

.calendar-tips {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #666;
  .icon-tips {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    background-color: #FFF8E8;
    border: 1px solid #E6E6E6;
  }
}

.calendar-cell {
  margin: 0 2px;
  height: 80px;
  border-top: 1px solid #E6E6E6;
  &.is-today {
    border-top-color: #E2A45A;
  }
}
.calendar-delivery-bg {
  background-color: #FFF8E8;
}
.calendar-date {
  padding: 7px 14px 0 0;
}