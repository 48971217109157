.preview-cont {
  margin: -24px auto;
  width: 1200px;
  height: 780px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-img {
  margin: 0 auto;
  display: block;
  max-width: 1200px;
  max-height: 780px;
}
.preview-label {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.preview-logo-wrap {
  display: flex;
  align-items: center;
}
.preview-logo {
  margin-right: 10px;
}