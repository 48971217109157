.list {
  
}
.no-record {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.bg-no-record {
  margin-bottom: 16px;
  width: 171px;
  height: 139px;
  background: url('../../../../assets/images/media/bg-norecode.png') no-repeat center;
  background-size: 100%;
}