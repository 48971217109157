@font-face {
  font-family: 'menufont';  /* Project id 2762974 */
  src: url('//at.alicdn.com/t/font_2762974_30hr03gvh5u.woff2?t=1629786319509') format('woff2'),
       url('//at.alicdn.com/t/font_2762974_30hr03gvh5u.woff?t=1629786319509') format('woff'),
       url('//at.alicdn.com/t/font_2762974_30hr03gvh5u.ttf?t=1629786319509') format('truetype');
}

.menufont {
  font-family: "menufont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-a-31:before {
  content: "\e6ee";
}

.i-a-2:before {
  content: "\e6d0";
}

.i-a-4:before {
  content: "\e6d1";
}

.i-a-3:before {
  content: "\e6d2";
}

.i-a-6:before {
  content: "\e6d3";
}

.i-a-8:before {
  content: "\e6d4";
}

.i-a-7:before {
  content: "\e6d5";
}

.i-a-5:before {
  content: "\e6d6";
}

.i-a-1:before {
  content: "\e6d7";
}

.i-a-12:before {
  content: "\e6d8";
}

.i-a-9:before {
  content: "\e6d9";
}

.i-a-14:before {
  content: "\e6da";
}

.i-a-15:before {
  content: "\e6db";
}

.i-a-10:before {
  content: "\e6dc";
}

.i-a-16:before {
  content: "\e6dd";
}

.i-a-11:before {
  content: "\e6de";
}

.i-a-13:before {
  content: "\e6df";
}

.i-a-23:before {
  content: "\e6e0";
}

.i-a-20:before {
  content: "\e6e1";
}

.i-a-22:before {
  content: "\e6e2";
}

.i-a-19:before {
  content: "\e6e3";
}

.i-a-18:before {
  content: "\e6e4";
}

.i-a-24:before {
  content: "\e6e5";
}

.i-a-17:before {
  content: "\e6e6";
}

.i-a-26:before {
  content: "\e6e7";
}

.i-a-28:before {
  content: "\e6e8";
}

.i-a-29:before {
  content: "\e6e9";
}

.i-a-25:before {
  content: "\e6ea";
}

.i-a-27:before {
  content: "\e6eb";
}

.i-a-21:before {
  content: "\e6ec";
}

.i-a-30:before {
  content: "\e6ed";
}

