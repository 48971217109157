.detail-cont {
  box-sizing: border-box;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 1;
  font-size: 14px;
  .detail-page-tit {
    margin: 0 -24px 24px;
    padding-left: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EDEDED;
    font-size: 20px;
    font-weight: bold;
  }
  .form-item-tit {
    margin-bottom: 16px;
    font-weight: bold;
  }
  .form-item-tips {
    margin-bottom: 16px;
    color: #999;
  }
  .img-list {
    margin: 16px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .img-item {
    position: relative;
    width: 480px;
    height: 270px;
    background: center center no-repeat;
    background-size: 100%;
    object-fit: contain;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &.img-item-bg {
      background-image: url(../../../assets/images/media/init.png);
      &:nth-child(2) {
        background-image: url(../../../assets/images/media/init1.png);
      }
      &:nth-child(3) {
        background-image: url(../../../assets/images/media/init2.png);
      }
    }
    
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    .btn-del {
      position: absolute;
      top: 0;
      right: 0;
      width: 58px;
      height: 51px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 0px 8px 0px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
      .icon-del {
        font-size: 22px;
      }
    }
    &.btn-upload {
      cursor: pointer;
    }
    .btn-add {
      width: 58px;
      height: 58px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
      .icon-add {
        font-size: 22px;
      }
    }
  }
}

.page-tips {
	padding: 18px 32px;
	background-color: #FFE4E4;
	display: flex;
  align-items: center;
	color: #666;
	.icon-tips {
		margin-right: 10px;
		color: #F52F3E;
	}
	.important {
		color: #FF4949;
	}
}
.form-switch {
  position: relative;
  margin-left: 150px;
  margin-bottom: -60px;
  z-index: 1;
}
.is-show {
  display: block;
}
.is-hide {
  display: none;
}
.point-industry-item {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
}
.point-industry-label {
  margin-right: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  background-color: rgba(226, 164, 90, 0.1);
  border-radius: 16px;
  text-align: center;
  color: #E2A45A;
}
.point-industry-values {
  margin-left: -16px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.point-industry-value {
  margin-bottom: 8px;
  margin-left: 16px;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #999;
  border-radius: 12px;
  text-align: center;
  color: #666;
}