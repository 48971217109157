.guide-dialog {
  height: inherit;
}

.guide-dialog header {
  height: 48px;
  background-color: #f5f5f5;
}

.main {
  height: calc(100% - 116px);
}

.guide-dialog footer {
  height: 68px;
  border-top: 1px solid #f5f5f5;
}