.income-page {

}

.income-page-tit {
  margin: 16px 0;
  font-size: 12px;
  font-weight: bold;
}

.income-page-cont {
	padding: 20px;
	background-color: #fff;
	border-radius: 4px;
}

.income-page-top {
	padding: 40px;
	display: flex;
	align-items: center;
}
.income-list {
	margin-right: 150px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
}
.income-item {
  position: relative;
  margin: 0 60px;
  flex: 1;
}
.income-item-tit {
  margin-bottom: 12px;
}
.income-unit {
	font-size: 12px;
}
.income-item-amount {
	font-weight: bold;
  font-size: 28px;
}
.btn-recharge {
  width: 80px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #E2A45A;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.income-date {
	margin: 40px 0;
}

.income-data {
	margin-bottom: 24px;
}
.income-data-tit {
	margin-bottom: 24px;
	padding: 20px;
	font-size: 20px;
	font-weight: bold;
	border-bottom: 1px solid #EDEDED;
}
.income-data-total {
	margin: 24px 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 66px;
	background-color: rgba(226,164,90,0.1);
	border-radius: 8px;
	text-align: center;
	font-size: 16px;
}

.income-table {
	margin-bottom: 24px;
}
.income-money {
	font-weight: bold;
}
.is-positive {
	color: #FE8135;
}
.important {
	color: #E2A45A;
}

.detail-modal-cont {
	min-height: 300px;
}
.order-form {
	margin-top: -10px;
	font-size: 16px;
}
.order-form-item {
	margin: 12px 0;
	display: flex;
	align-items: center;
}
.order-form-label {
	min-width: 100px;
	color: #999;
}

.other-tit {
	margin-top: 24px;
	padding-top: 12px;
	font-size: 16px;
	font-weight: bold;
	border-top: 1px dashed #DBDBDB;
}
.record-item {
	margin: 12px 0;
}
.record-item-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.record-item-top-l,
.record-item-top-r {
	display: flex;
	align-items: center;
}
.record-item-label {
	margin-right: 6px;
	color: #999;
}
.record-money {
	font-weight: bold;
}