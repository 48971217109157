.ant-menu-submenu-selected {
  color: #E2A45A;
}
.ant-menu-submenu-title:hover,
.ant-menu-item:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #E2A45A !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background-color: rgba(226, 164, 90, 0.1);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(226, 164, 90, 0.1);
  color: #E2A45A;
} 
.ant-menu-inline .ant-menu-item::after {
  border-right-color: #E2A45A;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .ant-menu-title-content {
	/* font-weight: bold; */
}


.ant-cascader-menu {
  width: 180px;
}

.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #333;
}

/*.ant-picker-cell.ant-picker-cell-in-view {
	height: 60px;
	background-color: #FFF8E8;
}
.ant-picker-cell-inner {
	position: relative;
	height: 60px !important;
}
.ant-picker-calendar-date-content {
	position: absolute !important;
	left: 0;
	right: 0;
	top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  overflow: hidden !important;
}*/